<template>
  <v-menu v-model="menu" :close-on-click="true" :close-on-content-click="false" class="float-right">
    <template v-slot:activator="{ on, attrs }" >
      <v-btn icon
             large
             v-bind="attrs"
             v-on="on">
        <v-icon>tune</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-0 pt-2 pr-2 pl-4">
        <span class="text-h6">View Settings</span>
        <v-spacer />
        <v-btn icon small @click="menu = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "Tune",
  data() {
    return {
      menu: false
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>