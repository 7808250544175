var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("span", [_vm._v(_vm._s(_vm.item.eventType))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.close } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.item.encrypted && _vm.item.decrypted) ||
                        !_vm.item.encrypted
                        ? _vm.item.eventBody
                          ? _vm.item.eventBody
                          : " "
                        : "Failed to decrypt"
                    )
                  )
                ])
              ]),
              _vm.isUrl &&
              ((_vm.item.encrypted && _vm.item.decrypted) ||
                !_vm.item.encrypted)
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "primary", "x-small": "", text: "" },
                          on: { click: _vm.openSite }
                        },
                        [_vm._v("Open Website")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "primary", "x-small": "", text: "" },
                          on: { click: _vm.openAnalyzer }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                "" +
                                  (_vm.item.unknown
                                    ? "Submit for analysis"
                                    : "Open analyzer")
                              )
                            )
                          ]),
                          _vm.item.unknown
                            ? _c("span", { staticClass: "ml-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.item.unknown ? _vm.unknownEmoji : ""
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.item.appName &&
              ((_vm.item.encrypted && _vm.item.decrypted) ||
                !_vm.item.encrypted)
                ? _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s("App Name: " + _vm.item.appName) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm.item.packageName &&
              ((_vm.item.encrypted && _vm.item.decrypted) ||
                !_vm.item.encrypted)
                ? _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s("Package: " + _vm.item.packageName) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _c("div", [_vm._v(_vm._s(_vm.item.timestampString))]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.item.encrypted ? _vm.keyEmoji : ""))
              ]),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary", "x-small": "" },
                  on: { click: _vm.openEventLegend }
                },
                [_vm._v("Open Event Legend")]
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "primary" },
                  on: { click: _vm.close }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }