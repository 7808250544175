var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.loading && !_vm.hideSubscribeContainer
        ? _c(
            "v-row",
            { staticClass: "centered pb-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  !_vm.$store.state.inApp
                    ? _c(
                        "v-alert",
                        [
                          _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("warning")
                          ]),
                          _vm._v(
                            "\n        You must subscribe in order to view your own screenshots.\n        "
                          ),
                          _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("warning")
                          ]),
                          _vm.$store.state.account.os !== "kindle"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "accent", block: "" },
                                  on: { click: _vm.goToSubscribePage }
                                },
                                [_vm._v("\n          Subscribe now!\n        ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "mb-3",
                          staticStyle: { width: "100%", "text-align": "center" }
                        },
                        [
                          _vm._v("\n        Your account is not subscribed."),
                          _c("br"),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "primary" },
                              on: { click: _vm.goToSubscribePage }
                            },
                            [_vm._v("\n          subscribe\n        ")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showSetupGuideAlert
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "float-right",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        dense: "",
                        outlined: "",
                        width: "280px",
                        type: "warning"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.openSetupGuide.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("div", [_vm._v("Complete Setup Guide")]),
                      _c("div", { staticClass: "caption text--primary" }, [
                        _vm._v(
                          "\n          You haven't completed the setup guide yet.  Click here to continue.\n        "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("loading", {
                    attrs: {
                      loading: _vm.loading,
                      message: "Loading event statistics..."
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      [
        _vm.hasUnknownSites &&
        !_vm.$store.state.submitAllAutomatically &&
        !_vm.$store.getters.hasSubmittedRatingsInLastDay &&
        _vm.$store.getters.isSubscribed
          ? _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "d-flex", attrs: { cols: 12 } },
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "mx-auto",
                        attrs: {
                          outlined: "",
                          type: "warning",
                          color: "secondary",
                          dense: "",
                          "max-width": "700px"
                        }
                      },
                      [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              "Unknown sites/apps found.  Please submit them for analysis to improve " +
                                _vm._s(_vm.appName) +
                                "'s monitoring."
                            )
                          ])
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "primary",
                              loading: _vm.loadingUnknownSites,
                              disabled: _vm.loadingUnknownSites
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToRatingCorrection(false)
                              }
                            }
                          },
                          [_vm._v("Learn more")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right ml-3",
                            attrs: { color: "primary" },
                            on: { click: _vm.setSubmitAllAutomatically }
                          },
                          [_vm._v("Always Submit")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right",
                            attrs: {
                              text: "",
                              loading: _vm.loadingUnknownSites,
                              disabled: _vm.loadingUnknownSites
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToRatingCorrection(true)
                              }
                            }
                          },
                          [_vm._v("Submit once")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.$store.getters.shouldBlockReportAccess
          ? _c(
              "div",
              {
                staticClass: "d-flex justify-center align-center",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "v-card",
                  { attrs: { outlined: "", "max-width": "500px" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _vm._v(
                          "\n          Your access to accountability reports is revoked due to your account lock.  You can regain access by unlocking your account from the "
                        ),
                        _c("router-link", { attrs: { to: "account" } }, [
                          _vm._v("accounts page")
                        ]),
                        _vm._v(".\n        ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._l(_vm.deviceEventStats, function(eventStatByAccount, index) {
              return _c(
                "v-row",
                {
                  key: "eventStatsFor_" + eventStatByAccount.accountName,
                  class: index > 0 ? "mt-10 mb-1 ml-1 mr-1" : "ma-1",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    !_vm.$store.state.inApp || _vm.$store.getters.isOwner
                      ? _c("div", { staticClass: "d-inline-block" }, [
                          _c(
                            "span",
                            {
                              staticClass: "headline",
                              staticStyle: { "word-break": "break-all" }
                            },
                            [_vm._v(_vm._s(eventStatByAccount.accountName))]
                          ),
                          _vm._v("  \n          "),
                          eventStatByAccount.accountName === "Your Account"
                            ? _c(
                                "span",
                                { staticClass: "caption" },
                                [
                                  !_vm.$store.state.inApp
                                    ? _c(
                                        "router-link",
                                        { attrs: { to: "allscreenshots" } },
                                        [_vm._v("(All screenshots)")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "span",
                                { staticClass: "caption" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "/dailyEmailUnsubscribe?recipient=" +
                                            encodeURIComponent(
                                              _vm.$store.state.loggedInEmail
                                            ) +
                                            "&account=" +
                                            encodeURIComponent(
                                              eventStatByAccount.accountId
                                            ) +
                                            "&frequency=" +
                                            encodeURIComponent(
                                              eventStatByAccount.frequency
                                            )
                                        }
                                      }
                                    },
                                    [_vm._v("(unsubscribe)")]
                                  )
                                ],
                                1
                              )
                        ])
                      : _vm._e(),
                    eventStatByAccount.accountName === "Your Account"
                      ? _c(
                          "div",
                          { staticClass: "d-inline-block float-right" },
                          [
                            _c("v-spacer"),
                            _c(
                              "tune",
                              { staticClass: "float-right" },
                              [
                                _c("v-switch", {
                                  staticClass: "mt-2",
                                  staticStyle: { "max-width": "350px" },
                                  attrs: {
                                    label: "High Risk Protection",
                                    hint:
                                      "When enabled, high risk screenshots will be extra blurry. You'll need to " +
                                      (_vm.$store.state.hasMouse
                                        ? "click"
                                        : "tap") +
                                      " and hold on the screenshot to view high risk screenshots clearly.",
                                    "persistent-hint": true
                                  },
                                  on: {
                                    change: _vm.blurHighRiskScreenshotsChange
                                  },
                                  model: {
                                    value: _vm.blurHighRiskScreenshots,
                                    callback: function($$v) {
                                      _vm.blurHighRiskScreenshots = $$v
                                    },
                                    expression: "blurHighRiskScreenshots"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  eventStatByAccount.loading
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v("\n        Loading...\n      ")
                      ])
                    : eventStatByAccount.error
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n        Error loading event stats...  please refresh the page.  If this persists, contact support@truple.io.\n      "
                        )
                      ])
                    : eventStatByAccount.deviceEventStats.length === 0 &&
                      eventStatByAccount.accountName !== "Your Account"
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              "You do not have permission to view any devices on this account.  This can be caused by " +
                                eventStatByAccount.accountName +
                                " deleting devices, or having not set the account up yet."
                            ) +
                            "\n      "
                        )
                      ])
                    : _vm.showNoDevices &&
                      eventStatByAccount.accountName === "Your Account"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-center pa-3",
                          staticStyle: { border: "dashed lightgray 1px" },
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "You have no devices on your account.  To get started "
                              ),
                              _c("router-link", { attrs: { to: "devices" } }, [
                                _vm._v("add a device")
                              ]),
                              _vm._v(".")
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._l(eventStatByAccount.deviceEventStats, function(
                        eventStat
                      ) {
                        return _c(
                          "v-col",
                          {
                            key: eventStat.deviceId + ".stats",
                            staticClass: "pa-1",
                            attrs: { cols: "12", sm: "6", lg: "4", xl: "3" }
                          },
                          [
                            _c("device-card", {
                              attrs: {
                                "event-stats": eventStat,
                                frequency: eventStatByAccount.frequency,
                                "can-view": eventStatByAccount.canView
                              },
                              on: {
                                "on-screenshots": _vm.showScreenshots,
                                "on-websites": _vm.showWebsites,
                                "on-events": _vm.showEvents,
                                "on-time": _vm.showScreenTime
                              }
                            })
                          ],
                          1
                        )
                      })
                ],
                2
              )
            })
      ],
      _vm.showScreenshotDialog
        ? _c("screenshot-dialog", {
            attrs: {
              device: _vm.device,
              risk: _vm.screenshotDialogRisk,
              "show-dialog": _vm.showScreenshotDialog,
              "days-to-load": _vm.daysToLoad
            },
            on: { "on-close": _vm.closeScreenshotDialog }
          })
        : _vm._e(),
      _vm.showEventDialog
        ? _c("event-dialog", {
            attrs: {
              device: _vm.eventDevice,
              "event-type": _vm.eventDialogEventType,
              risk: _vm.eventDialogRisk,
              "show-dialog": _vm.showEventDialog
            },
            on: { "on-close": _vm.closeEventDialog }
          })
        : _vm._e(),
      _vm.showWebsiteDialog
        ? _c("website-dialog", {
            attrs: {
              device: _vm.websiteDevice,
              risk: _vm.websiteDialogRisk,
              "show-dialog": _vm.showWebsiteDialog
            },
            on: { "on-close": _vm.closeWebsiteDialog }
          })
        : _vm._e(),
      _vm.showScreenTimeDialog
        ? _c("screen-time-dialog", {
            attrs: {
              device: _vm.screenTimeDevice,
              "show-dialog": _vm.showScreenTimeDialog
            },
            on: { "on-close": _vm.closeScreenTimeDialog }
          })
        : _vm._e(),
      _c("email-recipient-invite-dialog", {
        on: { "on-accept": _vm.forceReload }
      }),
      _c("e2-e-e-recovery-password-request"),
      _c("add-device-request-dialog", { on: { "on-accept": _vm.forceReload } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }