var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", fullscreen: "" },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary" } },
            [
              !_vm.$store.state.isMobile
                ? _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v(_vm._s("" + _vm.device.deviceName))
                  ])
                : _vm._e(),
              _c("v-spacer"),
              !_vm.$store.state.isMobile
                ? _c(
                    "div",
                    {
                      staticClass: "pt-6 mr-1",
                      staticStyle: { display: "inline-block" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "text--white",
                        attrs: {
                          loading: _vm.eventFilterLoading,
                          color: "accent",
                          placeholder: "Search",
                          outlined: "",
                          dense: "",
                          clearable: "",
                          disabled: _vm.loading,
                          "background-color": "white"
                        },
                        on: { change: _vm.startFilterTimeout },
                        model: {
                          value: _vm.tempEventFilter,
                          callback: function($$v) {
                            _vm.tempEventFilter = $$v
                          },
                          expression: "tempEventFilter"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pt-6 mr-1",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-select", {
                    staticClass: "text--white",
                    attrs: {
                      items: _vm.correctedEventTypes,
                      color: "accent",
                      multiple: "",
                      placeholder: "Event Type",
                      outlined: "",
                      dense: "",
                      disabled: _vm.loading,
                      "background-color": "white"
                    },
                    model: {
                      value: _vm.selectedTypeFilters,
                      callback: function($$v) {
                        _vm.selectedTypeFilters = $$v
                      },
                      expression: "selectedTypeFilters"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "pt-6",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-select", {
                    staticClass: "text--white",
                    attrs: {
                      items: _vm.riskLabelsByValue,
                      multiple: "",
                      color: "accent",
                      placeholder: "Risk",
                      outlined: "",
                      dense: "",
                      disabled: _vm.loading,
                      "background-color": "white"
                    },
                    model: {
                      value: _vm.selectedRiskFilters,
                      callback: function($$v) {
                        _vm.selectedRiskFilters = $$v
                      },
                      expression: "selectedRiskFilters"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.onClose } },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pl-0 pr-0 pb-0" },
            [
              _vm.filteredEvents.length <= 0 && !_vm.loading
                ? _c("div", { staticClass: "pa-1 mt-5 text-center" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        "No events.  Try loading more, or clearing filters."
                      )
                    ]),
                    _vm.current > _vm.currentShutoff
                      ? _c(
                          "div",
                          { staticClass: "d-flex text-h6" },
                          [
                            _c("v-spacer"),
                            _vm._v(
                              "\n            load\n             \n            "
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading: _vm.loading6,
                                  disabled: _vm.loading,
                                  outlined: "",
                                  color: "primary"
                                },
                                on: { click: _vm.loadMore }
                              },
                              [_vm._v("\n              6\n            ")]
                            ),
                            _vm._v("\n             \n            "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading: _vm.loading24,
                                  disabled: _vm.loading,
                                  outlined: "",
                                  color: "primary"
                                },
                                on: { click: _vm.load24MoreHours }
                              },
                              [_vm._v("\n              24\n            ")]
                            ),
                            _vm._v(
                              "\n             \n            more hours\n            "
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _c(
                    "v-timeline",
                    {
                      staticClass: "pl-1 pr-1 ml-0 mr-0 pt-1 pb-1",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-virtual-scroll", {
                        attrs: {
                          items: _vm.filteredEvents,
                          "item-height": _vm.eventCardHeight,
                          height: _vm.scrollerHeight,
                          bench: 1
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var index = ref.index
                              var item = ref.item
                              return [
                                !item.loadMore
                                  ? _c(
                                      "v-timeline-item",
                                      {
                                        attrs: {
                                          icon: _vm.getIconName(item),
                                          color: _vm.getIconColor(item)
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "pt-1 pb-0 text-truncate"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      (item.encrypted &&
                                                        item.decrypted) ||
                                                        !item.encrypted
                                                        ? item.eventTitle
                                                        : item.eventType
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pb-1" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-truncate clickable",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEventDialogForEvent(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    item.eventType !==
                                                      "App Opened" &&
                                                    item.eventType !==
                                                      "App Closed"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                (item.encrypted &&
                                                                  item.decrypted) ||
                                                                  !item.encrypted
                                                                  ? item.eventBody
                                                                    ? item.eventBody
                                                                    : " "
                                                                  : "Failed to decrypt"
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                (item.encrypted &&
                                                                  item.decrypted) ||
                                                                  !item.encrypted
                                                                  ? item.packageName
                                                                    ? item.packageName
                                                                    : " "
                                                                  : "Failed to decrypt"
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ])
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "caption text-truncate"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.timestampString
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.encrypted
                                                            ? _vm.keyEmoji
                                                            : ""
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "d-flex text-h6" },
                                      [
                                        _c("v-spacer"),
                                        _vm._v(
                                          "\n                load\n                 \n                "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loading6,
                                              disabled: _vm.loading,
                                              outlined: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.loadMore }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  6\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                 \n                "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loading24,
                                              disabled: _vm.loading,
                                              outlined: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.load24MoreHours }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  24\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                 \n                more hours\n                "
                                        ),
                                        _c("v-spacer")
                                      ],
                                      1
                                    )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
              _c(
                "v-speed-dial",
                {
                  attrs: { bottom: "", right: "", fixed: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "accent",
                                dark: "",
                                fab: "",
                                bottom: "",
                                right: ""
                              },
                              model: {
                                value: _vm.fab,
                                callback: function($$v) {
                                  _vm.fab = $$v
                                },
                                expression: "fab"
                              }
                            },
                            [
                              _vm.fab
                                ? _c("v-icon", [
                                    _vm._v(
                                      "\n                close\n              "
                                    )
                                  ])
                                : _c("v-icon", [
                                    _vm._v(
                                      "\n                menu\n              "
                                    )
                                  ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.fab,
                    callback: function($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab"
                  }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "", "nudge-left": "25" },
                      model: {
                        value: _vm.tooltip,
                        callback: function($$v) {
                          _vm.tooltip = $$v
                        },
                        expression: "tooltip"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            slot: "activator",
                            fab: "",
                            color: "primary",
                            href:
                              "https://blog.truple.io/2020/11/14/event-timeline-legend.html",
                            target: "_blank"
                          },
                          slot: "activator"
                        },
                        [_c("v-icon", [_vm._v("vpn_key")])],
                        1
                      ),
                      _c("span", [_vm._v("View Event Legend")])
                    ],
                    1
                  ),
                  !_vm.$store.state.inApp
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { left: "", "nudge-left": "25" },
                          model: {
                            value: _vm.tooltip2,
                            callback: function($$v) {
                              _vm.tooltip2 = $$v
                            },
                            expression: "tooltip2"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                slot: "activator",
                                fab: "",
                                color: "primary",
                                target: "_blank"
                              },
                              on: { click: _vm.downloadEventLog },
                              slot: "activator"
                            },
                            [_c("v-icon", [_vm._v("file_download")])],
                            1
                          ),
                          _c("span", [_vm._v("Download Event Log")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: true },
          model: {
            value: _vm.showMessage,
            callback: function($$v) {
              _vm.showMessage = $$v
            },
            expression: "showMessage"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.showMessage = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _c("single-event-dialog", {
        attrs: {
          "show-dialog": _vm.showEventDialog,
          item: _vm.eventDialogItem
        },
        on: {
          close: function($event) {
            _vm.showEventDialog = false
          },
          closeall: _vm.cleanupForClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }