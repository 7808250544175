<template>
  <v-card class="pa-2">
    <div style="text-align: center;" class="mb-1 headline">{{`App Usage from previous ${this.hoursLoaded} hours`}}</div>
    <v-divider></v-divider>
    <bar-chart :data="appTimes"
               ytitle="Apps used"
               xtitle="Time spent"
               suffix=" mins"
               :height="`${appTimes.length * 20 + 100}px`"
               :colors="['#FFC107', '#00796B']"></bar-chart>
  </v-card>
</template>

<script>
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";

  export default {
    name: "AppUsageChart",
    props: {
      appTimes: {
        required: true
      },
      current: {
        required: true
      }
    },
    computed: {
      hoursLoaded() {
        return Math.round((AWSClockSkewHandler.getTime() - this.current) / (60 * 60 * 1000))
      }
    }
  }
</script>

<style scoped>

</style>