var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-2" },
    [
      _c(
        "div",
        {
          staticClass: "mb-1 headline",
          staticStyle: { "text-align": "center" }
        },
        [
          _vm._v(
            _vm._s("App Usage from previous " + this.hoursLoaded + " hours")
          )
        ]
      ),
      _c("v-divider"),
      _c("bar-chart", {
        attrs: {
          data: _vm.appTimes,
          ytitle: "Apps used",
          xtitle: "Time spent",
          suffix: " mins",
          height: _vm.appTimes.length * 20 + 100 + "px",
          colors: ["#FFC107", "#00796B"]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }