<template>
  <v-container fluid>
    <v-row v-if="!loading && !hideSubscribeContainer" class="centered pb-3">
      <v-col cols="12">
        <v-alert v-if="!$store.state.inApp">
          <v-icon color="error">warning</v-icon>
          You must subscribe in order to view your own screenshots.
          <v-icon color="error">warning</v-icon>
          <v-btn
            v-if="$store.state.account.os !== 'kindle'"
            color="accent"
            class="mt-2"
            @click="goToSubscribePage"
            block>
            Subscribe now!
          </v-btn>
        </v-alert>
        <div style="width: 100%; text-align: center;" class="mb-3" v-else>
          Your account is not subscribed.<br />
          <v-btn small color="primary" @click="goToSubscribePage">
            subscribe
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showSetupGuideAlert">
      <v-col cols="12">
        <v-alert
            dense
            outlined
            width="280px"
            type="warning"
            class="float-right"
            style="cursor: pointer;"
            @click.native="openSetupGuide"
        >

          <div>Complete Setup Guide</div>
          <div class="caption text--primary">
            You haven't completed the setup guide yet.  Click here to continue.
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <loading :loading="loading" message="Loading event statistics..."></loading>
      </v-col>
    </v-row>
    <template v-show="!loading">
      <v-row v-if="hasUnknownSites && !$store.state.submitAllAutomatically && !$store.getters.hasSubmittedRatingsInLastDay && $store.getters.isSubscribed">
        <v-col :cols="12" class="d-flex">
          <v-alert outlined type="warning" color="secondary" dense max-width="700px" class="mx-auto">
            <div><span>Unknown sites/apps found.  Please submit them for analysis to improve {{appName}}'s monitoring.</span></div>
            <v-btn text color="primary" @click="goToRatingCorrection(false)" :loading="loadingUnknownSites" :disabled="loadingUnknownSites">Learn more</v-btn>
            <v-btn class="float-right ml-3" color="primary" @click="setSubmitAllAutomatically" >Always Submit</v-btn>
            <v-btn class="float-right" text @click="goToRatingCorrection(true)" :loading="loadingUnknownSites" :disabled="loadingUnknownSites">Submit once</v-btn>
          </v-alert>
        </v-col>
      </v-row>

      <div v-if="$store.getters.shouldBlockReportAccess" style="width: 100%;" class="d-flex justify-center align-center">
        <v-card outlined max-width="500px">
          <v-card-text>
            Your access to accountability reports is revoked due to your account lock.  You can regain access by unlocking your account from the <router-link to="account">accounts page</router-link>.
          </v-card-text>
        </v-card>
      </div>
      <v-row
          v-else
          v-for="(eventStatByAccount, index) in deviceEventStats"
             :key="`eventStatsFor_${eventStatByAccount.accountName}`"
             :class="index > 0 ? 'mt-10 mb-1 ml-1 mr-1' : 'ma-1'"
             no-gutters>
        <v-col cols="12">

          <div v-if="!$store.state.inApp || $store.getters.isOwner" class="d-inline-block">
            <span class="headline" style="word-break: break-all;">{{eventStatByAccount.accountName}}</span>&nbsp;&nbsp;
            <span v-if="eventStatByAccount.accountName === 'Your Account'" class="caption"><router-link v-if="!$store.state.inApp" to="allscreenshots">(All screenshots)</router-link></span>
            <span v-else class="caption"><router-link :to="{ path: `/dailyEmailUnsubscribe?recipient=${encodeURIComponent($store.state.loggedInEmail)}&account=${encodeURIComponent(eventStatByAccount.accountId)}&frequency=${encodeURIComponent(eventStatByAccount.frequency)}` }">(unsubscribe)</router-link></span>
          </div>

          <div v-if="eventStatByAccount.accountName === 'Your Account'" class="d-inline-block float-right">
            <v-spacer />
            <tune class="float-right">
              <v-switch
                  style="max-width: 350px;"
                  class="mt-2"
                  v-model="blurHighRiskScreenshots"
                  label="High Risk Protection"
                  :hint="`When enabled, high risk screenshots will be extra blurry. You'll need to ${$store.state.hasMouse ? 'click' : 'tap'} and hold on the screenshot to view high risk screenshots clearly.`"
                  @change="blurHighRiskScreenshotsChange"
                  :persistent-hint="true"
              />
            </tune>
          </div>
        </v-col>
        <v-col cols="12" v-if="eventStatByAccount.loading">
          Loading...
        </v-col>
        <v-col cols="12" v-else-if="eventStatByAccount.error">
          Error loading event stats...  please refresh the page.  If this persists, contact support@truple.io.
        </v-col>
        <v-col cols="12" v-else-if="eventStatByAccount.deviceEventStats.length === 0 && eventStatByAccount.accountName !== 'Your Account'">
          {{`You do not have permission to view any devices on this account.  This can be caused by ${eventStatByAccount.accountName} deleting devices, or having not set the account up yet.`}}
        </v-col>
        <v-col cols="12" v-else-if="showNoDevices && eventStatByAccount.accountName === 'Your Account'" class="text-center pa-3" style="border: dashed lightgray 1px;">
          <span>You have no devices on your account.  To get started <router-link to="devices">add a device</router-link>.</span>
        </v-col>
        <template v-else>
          <v-col cols="12" sm="6" lg="4" xl="3" v-for="eventStat in eventStatByAccount.deviceEventStats" :key="`${eventStat.deviceId}.stats`" class="pa-1">
            <device-card :event-stats="eventStat"
                         :frequency="eventStatByAccount.frequency"
                         :can-view="eventStatByAccount.canView"
                         @on-screenshots="showScreenshots"
                         @on-websites="showWebsites"
                         @on-events="showEvents"
                         @on-time="showScreenTime"
            />
          </v-col>
        </template>
      </v-row>
    </template>

      <screenshot-dialog
        v-if="showScreenshotDialog"
        :device="device"
        :risk="screenshotDialogRisk"
        :show-dialog="showScreenshotDialog"
        :days-to-load="daysToLoad"
        @on-close="closeScreenshotDialog" />

      <event-dialog
        v-if="showEventDialog"
        :device="eventDevice"
        :event-type="eventDialogEventType"
        :risk="eventDialogRisk"
        :show-dialog="showEventDialog"
        @on-close="closeEventDialog" />

      <website-dialog
        v-if="showWebsiteDialog"
        :device="websiteDevice"
        :risk="websiteDialogRisk"
        :show-dialog="showWebsiteDialog"
        @on-close="closeWebsiteDialog" />

      <screen-time-dialog
        v-if="showScreenTimeDialog"
        :device="screenTimeDevice"
        :show-dialog="showScreenTimeDialog"
        @on-close="closeScreenTimeDialog" />

    <email-recipient-invite-dialog @on-accept="forceReload" />

    <e2-e-e-recovery-password-request />

    <add-device-request-dialog @on-accept="forceReload" />

  </v-container>
</template>

<script>
  import Loading from './Loading'
  import StoreConstants from "../StoreConstants";
  import eventsApi from "../aws/events/eventsApi";
  import DeviceCard from "./home/DeviceCard";
  import ScreenshotDialog from "./home/ScreenshotDialog";
  import EventDialog from "./home/EventDialog";
  import WebsiteDialog from "./home/WebsiteDialog";
  import ScreenTimeDialog from "./home/ScreenTimeDialog";
  import AWSClockSkewHandler from "../aws/AWSClockSkewHandler";
  import EmailRecipientInviteDialog from "./EmailRecipientInviteDialog";
  import E2EERecoveryPasswordRequest from "./e2ee/E2EERecoveryPasswordRequest";
  import AndroidInterface from "../services/AndroidInterface";
  import SegmentTracking from "../services/SegmentTracking";
  import Tune from "@/components/Tune";
  import AddDeviceRequestDialog from "@/components/AddDeviceRequestDialog";
  import E2EEHelperCache from "@/services/e2ee/E2EEHelperCache";
  import Utils from "@/Utils";
  import ImagesApi from "@/aws/images/ImagesApi";
  import AccountApi from "@/aws/account/AccountApi";

  let unsubscribeFunctions = []

  const createEventStatsQuery = (numberOfDays) => {
    let now = AWSClockSkewHandler.getTime()
    let statsRequest = []
    for(let c = 0; c < 24 * numberOfDays; c++) {
      statsRequest.push({
        timestamp: now - c * 60 * 60 * 1000
      })
    }

    return statsRequest
  }

  const eventStatsSort = (a, b) => {
    if(a.totalTamperEvents > b.totalTamperEvents) {
      return -1
    } else if(a.totalTamperEvents < b.totalTamperEvents) {
      return 1
    } else if(a.totalTamperEvents === b.totalTamperEvents) {
      if(a.totalHighRiskEvents > b.totalHighRiskEvents) {
        return -1
      } else if(a.totalHighRiskEvents < b.totalHighRiskEvents) {
        return 1
      } else if(a.totalHighRiskEvents === b.totalHighRiskEvents) {
        return b.totalEvents - a.totalEvents
      }
    }
  }

  const deviceEventStatsMergeFunc = (map, currentValue) => {

    let deviceId = currentValue.deviceId

    if(!map[deviceId]) {
      map[deviceId] = currentValue
    } else {
      map[deviceId].totalScreenshots += currentValue.totalScreenshots
      map[deviceId].totalHighRiskScreenshots += currentValue.totalHighRiskScreenshots
      map[deviceId].totalEvents += currentValue.totalEvents
      map[deviceId].totalHighRiskEvents += currentValue.totalHighRiskEvents
      map[deviceId].totalTamperEvents += currentValue.totalTamperEvents
      map[deviceId].totalWebsites += currentValue.totalWebsites
      map[deviceId].totalHighRiskWebsites += currentValue.totalHighRiskWebsites
      map[deviceId].unknownWebsites = (map[deviceId].unknownWebsites || []).concat(currentValue.unknownWebsites || [])
      map[deviceId].unknownApps = (map[deviceId].unknownApps || []).concat(currentValue.unknownApps || [])
      map[deviceId].appVersion = map[deviceId].appVersion || currentValue.appVersion
    }
    return map
  }

  export default {
    name: "HomeV2",
    components: {
      AddDeviceRequestDialog,
      Tune,
      E2EERecoveryPasswordRequest,
      EmailRecipientInviteDialog,
      ScreenTimeDialog,
      WebsiteDialog,
      EventDialog,
      ScreenshotDialog,
      DeviceCard,
      Loading
    },
    data() {
      return {
        appHost: process.env.VUE_APP_HOST,
        appName: process.env.VUE_APP_NAME,
        loadingUnknownSites: false,
        blurHighRiskScreenshots: this.$store.state.viewSettings ? !!this.$store.state.viewSettings.blurHighRiskScreenshots : false,
        hideSubscribeContainer: false,
        daysToLoad: 1,
        loading: true,
        deviceEventStats: [],
        device: {},
        eventDevice: {},
        websiteDevice: {},
        screenTimeDevice: {},
        appUrl: process.env.VUE_APP_HOST,
        showScreenshotDialog: false,
        showScreenTimeDialog: false,
        eventDialogRisk: null,
        websiteDialogRisk: null,
        screenshotDialogRisk: null,
        timeDisplayString: 'in the last 24 hours',
        showEventDialog: false,
        showWebsiteDialog: false,
        eventDialogEventType: null,
        e2eeHelpers: []
      }
    },
    created() {
      this.identitiesCheck()
    },
    methods: {
      async handleSubmitAllAutomaticallyOnMount() {
        await this.$store.state.accountLoadedPromise

        if(this.$store.state.submitAllAutomatically && this.hasUnknownSites) {
          await this.submitAllUnknownAppsWebsites()
        }
      },
      setSubmitAllAutomatically() {
        this.$store.commit(StoreConstants.commits.setSubmitAllAutomatically, true)
        this.submitAllUnknownAppsWebsites()
      },
      async submitAllUnknownAppsWebsites() {
        if(this.$store.getters.hasSubmittedRatingsInLastDay) {
          console.log('already submitted ratings within the past 24 hours')
          return
        }
        const { unknownSites, unknownApps } = await this.getUnknownSitesApps()
        const sites = Array.from(unknownSites)
        const apps = Array.from(unknownApps)

        for(let s of sites) {
          if(!s) {
            continue
          }

          try {
            await ImagesApi.submitWebsiteCorrection(s)
          } catch(e) {
            console.log(e)
          }
        }

        for(let a of apps) {
          if(!a) {
            continue
          }

          try {
            await ImagesApi.submitAppCorrection(a)
          } catch(e) {
            console.log(e)
          }
        }
      },
      async getUnknownSitesApps() {
        const unknownSites = new Set()
        const unknownApps = new Set()
        for(let statsObj of this.deviceEventStats) {
          const accountId = statsObj.accountId
          const e2eeHelper = E2EEHelperCache.getE2EEHelper(accountId)
          await e2eeHelper.initPromise
          for(let oObj of statsObj.deviceEventStats) {

            if(oObj.unknownApps && oObj.unknownApps.length > 0) {
              for (let event of oObj.unknownApps) {
                let unknownApp = event.packageName
                if(event.encrypted) {
                  try {
                    unknownApp = await e2eeHelper.decryptString(oObj.deviceId, event.packageName, event.timestamp)
                  } catch(e) {
                    console.log(e)
                    continue
                  }
                }
                if(unknownApp && unknownApp.indexOf('.') !== -1) {
                  unknownApps.add(unknownApp)
                }
              }
            }

            if(oObj.unknownWebsites && oObj.unknownWebsites.length > 0){
              for(let event of oObj.unknownWebsites) {
                let unknownSite = event.eventBody
                if(event.encrypted) {
                  try {
                    unknownSite = await e2eeHelper.decryptString(oObj.deviceId, event.eventBody, event.timestamp)
                  } catch(e) {
                    console.log(e)
                    continue
                  }
                }
                let url = Utils.getUrl(unknownSite)
                if(url) {
                  let host = url.host
                  if(host.startsWith('www.')) {
                    host = host.substring(4)
                  }
                  if(host && host.indexOf('.') !== -1) {
                    unknownSites.add(host)
                  }
                } else {
                  console.log(unknownSite)
                }
              }
            }
          }
        }
        return {
          unknownSites,
          unknownApps
        }
      },
      async goToRatingCorrection(submit) {
        this.loadingUnknownSites = true

        const { unknownSites, unknownApps } = await this.getUnknownSitesApps()

        await this.$store.commit(StoreConstants.commits.setUnknown, {
          sites: Array.from(unknownSites),
          apps: Array.from(unknownApps)
        })

        const route = {
          path: 'rating'
        }
        if(submit) {
          route.query = {
            submit: true
          }
        }

        await this.$router.push(route)
        this.loadingUnknownSites = false
      },
      blurHighRiskScreenshotsChange() {
        console.log('blurHighRiskScreenshotsChange changed ' + this.blurHighRiskScreenshots)
        this.$store.commit(StoreConstants.commits.setViewSettings, {
          blurHighRiskScreenshots: this.blurHighRiskScreenshots
        })
      },
      openSetupGuide() {
        if(this.$store.state.inApp && !this.$store.state.appVersion) {
          // AndroidInterface.openAppPageInBrowser('setup')
          AndroidInterface.openWebsiteInBrowser(`${process.env.VUE_APP_HOST}/setup`)
        } else {
          this.$router.push({
            path: 'setup'
          })
        }
      },
      goToSubscribePage() {
        // if(this.$store.state.inApp && AndroidInterface.canUseGooglePlayForPurchases()) {
        //   AndroidInterface.openAppPageInBrowser('account')
        // } else {
          console.log('subscribe!')
          this.$router.push({
            path: 'account',
            query: {
              subscribe: true
            }
          })
        // }
      },
      closeScreenTimeDialog() {
        this.showScreenTimeDialog = false
        this.screenTimeDevice = {}
      },
      async showE2EERequiredWarning() {
        let response = await this.$swal({
          icon: 'warning',
          title: 'Enable end-to-end encryption',
          text: 'Your account does not have end-to-end encryption (E2EE) enabled.  Please enable it to avoid being charged a $5 / month non-compliance fee.',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: 'Enable E2EE',
          cancelButtonText: 'Later'
        })

        if(response.isConfirmed) {
          this.openAccountPageForEnablingE2EE()
        }
      },
      stripeSubscription() {
        return this.$store.state.account &&
        this.$store.state.account.account &&
        this.$store.state.account.account.accountSettings &&
        this.$store.state.account.account.accountSettings.subscriptionProcessor === 'stripe'
      },
      async showScreenTime(device) {

        if(this.shouldShowE2EERequiredPrompt()) {

          if(!this.stripeSubscription()) {
            await this.$swal({
              icon: 'warning',
              title: 'Enable end-to-end encryption',
              text: 'Enable end-to-end encryption (E2EE) in order to view screen & app time.',
              showCancelButton: false,
              confirmButtonText: 'Enable E2EE'
            })

            this.openAccountPageForEnablingE2EE()

            return
          } else {
            await this.showE2EERequiredWarning()
          }
        }

        if(this.showScreenTimeDialog) {
          this.showScreenTimeDialog = false
          this.nextTick(() => {
            this.screenTimeDevice = device
            this.showScreenTimeDialog = true
          })
        } else {
          this.screenTimeDevice = device
          this.showScreenTimeDialog = true
        }
      },
      closeWebsiteDialog() {
        this.showWebsiteDialog = false
        this.websiteDevice = {}
      },
      async showWebsites(device, risk) {

        if(this.shouldShowE2EERequiredPrompt()) {

          if(!this.stripeSubscription()) {
            await this.$swal({
              icon: 'warning',
              title: 'Enable end-to-end encryption',
              text: 'Enable end-to-end encryption (E2EE) in order to view websites.',
              showCancelButton: false,
              confirmButtonText: 'Enable E2EE'
            })

            this.openAccountPageForEnablingE2EE()

            return
          } else {
            await this.showE2EERequiredWarning()
          }
        }

        if(this.showWebsiteDialog) {
          this.showWebsiteDialog = false
          this.nextTick(() => {
            this.websiteDevice = device
            this.showWebsiteDialog = true
            this.websiteDialogRisk = risk
          })
        } else {
          this.websiteDevice = device
          this.showWebsiteDialog = true
          this.websiteDialogRisk = risk
        }
      },
      closeEventDialog() {
        this.showEventDialog = false
        this.eventDevice = {}
        this.eventDialogEventType = null
        this.eventDialogRisk = null
      },
      shouldShowE2EERequiredPrompt() {
        return this.$store.getters.isSubscribed &&
            (
                !this.$store?.state?.account?.account?.createdTimestamp ||
                this.$store.state.account.account.createdTimestamp < (new Date().getTime() - 15 * 24 * 60 * 60 * 1000)
            ) &&
            this.$store.getters.isOwner &&
            !this.$store.getters.isE2EEEnabled
      },
      openAccountPageForEnablingE2EE() {
        //we want this to push to the accounts page, and automatically open the e2ee dialog for enabling e2ee.
        // The problem is the android app, the WebView captures that request since it's for /account, and opens the account tab (which loads the account page)
        //  but looses the forceEnableE2ee query string parameter that causes the e2ee dialog to automatically open.
        //  So our hack was to duplicate the account page under /inAppAccountHack.  Since the android app doesn't know this route, it won't be captured
        //  and will allow the dialog to open automatically.  This has the downside that the account tab in the menu won't appear as if it's opened, but it should
        //  be fine because when forceEnableE2ee is set, and then the dialog is closed, the webapp will call back() (ie, click the back button) to take the user back
        // to the home page.

        if(this.$store.state.inApp) {
          this.$router.push({
            path: 'inAppAccountHack',
            query: { forceEnableE2ee: true }
          })
        } else {
          this.$router.push({
            path: 'account',
            query: { forceEnableE2ee: true }
          })
        }
      },
      async showEvents(device, eventType, risk) {

        if(this.shouldShowE2EERequiredPrompt()) {

          if(this.stripeSubscription()) {
            await this.$swal({
              icon: 'warning',
              title: 'Enable end-to-end encryption',
              text: 'Enable end-to-end encryption (E2EE) in order to view events.',
              showCancelButton: false,
              confirmButtonText: 'Enable E2EE'
            })

            this.openAccountPageForEnablingE2EE()

            return
          } else {
            await this.showE2EERequiredWarning()
          }
        }

        if(this.showEventDialog) {
          this.showEventDialog = false
          this.nextTick(() => {
            this.eventDevice = device
            this.eventDialogEventType = eventType
            this.showEventDialog = true
            this.eventDialogRisk = risk
          })
        } else {
          this.eventDevice = device
          this.eventDialogEventType = eventType
          this.showEventDialog = true
          this.eventDialogRisk = risk
        }
      },
      closeScreenshotDialog() {
        this.showScreenshotDialog = false
        this.device = {}
        this.screenshotDialogRisk = null
      },
      async showScreenshots(device, risk, daysToLoad) {

        if(this.shouldShowE2EERequiredPrompt()) {

          if(this.stripeSubscription()) {
            await this.$swal({
              icon: 'warning',
              title: 'Enable end-to-end encryption',
              text: 'Enable end-to-end encryption (E2EE) in order to view screenshots.',
              showCancelButton: false,
              confirmButtonText: 'Enable E2EE'
            })

            this.openAccountPageForEnablingE2EE()

            return
          } else {
            await this.showE2EERequiredWarning()
          }
        }

        console.log('showScreenshots homev2')
        console.log(risk)
        console.log(device)
        if(this.showScreenshotDialog) {
          this.showScreenshotDialog = false
            this.nextTick(() => {
            this.showScreenshotDialog = true
            this.screenshotDialogRisk = risk
            this.device = device
            this.daysToLoad = daysToLoad
          })
        } else {
          this.showScreenshotDialog = true
          this.screenshotDialogRisk = risk
          this.device = device
          this.daysToLoad = daysToLoad
        }
      },
      unsubscribeFromStore() {
        for(let u of unsubscribeFunctions) {
          u();
          unsubscribeFunctions.pop();
        }
      },
      async forceReload() {
        this.deviceEventStats = []
        this.loading = true
        await Utils.sleep(5000)
        await this.loadEventStats()
      },
      async loadEventStats() {
        if(this.deviceEventStats.length > 0) {
          console.log('already loaded')
          return
        }

        if(this.$store.getters.shouldBlockReportAccess) {
          console.log('reports blocked')
          this.loading = false
          return
        }

        let statsRequest = createEventStatsQuery(1)

        let request = {
          stats: statsRequest
        }

        try {

          let tempDeviceEventStats = []

          let obj = {
            accountId: this.$store.state.account.account.accountId,
            accountName: 'Your Account',
            loading: false,
            deviceEventStats: null,
            frequency: 'daily'
          }

          let response = await eventsApi.queryEventStats(request)

          if(!response) {
            obj.error = true
            obj.loading = false
            return
          }

          let deviceEventStats = response.data.stats

          let mergedByDeviceId = deviceEventStats.reduce(deviceEventStatsMergeFunc, {})

          deviceEventStats = Object.values(mergedByDeviceId)

          deviceEventStats.sort(eventStatsSort)

          obj.deviceEventStats = deviceEventStats

          tempDeviceEventStats.push(obj)

          this.loading = false

          let emailRecipientForAccounts = response.data.emailRecipientForAccounts

          console.log(emailRecipientForAccounts)

          console.log(`isOwner = ${this.$store.getters.isOwner}`)

          if(emailRecipientForAccounts && (!this.$store.state.inApp || this.$store.getters.isOwner)) {

            let accountIdSet = new Set()

            emailRecipientForAccounts.sort((a, b) => {
              if(a.frequency === b.frequency) {
                return 0
              } else if(a.frequency === 'weekly') {
                return -1
              } else if(b.frequency === 'weekly') {
                return 1
              } else {
                return 0
              }
            })

            for(let recipientObj of emailRecipientForAccounts) {

              let accountId = recipientObj.accountId
              if(accountIdSet.has(accountId)) {
                // already loaded
                continue
              }

              accountIdSet.add(accountId)

              let obj = {
                accountName: accountId,
                accountId: accountId,
                frequency: recipientObj.frequency,
                deviceEventStats: [],
                canView: recipientObj.canView,
                loading: true
              }

              tempDeviceEventStats.push(obj)

              request.forAccountId = accountId
              request.stats = createEventStatsQuery(recipientObj.frequency === 'weekly' ? 7 : 1)
              response = await eventsApi.queryEventStats(request)

              if(!response) {
                obj.error = true
                obj.loading = false
                continue
              }

              deviceEventStats = response.data.stats
              deviceEventStats.sort(eventStatsSort)

              let mergedByDeviceId = deviceEventStats.reduce(deviceEventStatsMergeFunc, {})
              deviceEventStats = Object.values(mergedByDeviceId)

              obj.deviceEventStats = deviceEventStats
              obj.loading = false
            }
          }

          this.deviceEventStats = tempDeviceEventStats
        } catch (err) {
          console.log(err)
          location.reload()
        } finally {
          this.loading = false
          this.handleSubmitAllAutomaticallyOnMount()
        }
      },
      identitiesCheck() {
        let unsubscribe = this.$store.subscribe((mutation, state) => {
          if(mutation.type === StoreConstants.commits.setAccount) {
            this.loadEventStats()
          }
        })

        unsubscribeFunctions.push(unsubscribe)

        if(this.$store.state.account && this.$store.state.account.account) {
          this.loadEventStats()
        }
      },
      openSurvey() {
        //https://cam153.typeform.com/to/mYTegoC5#altaccountid=xxxxx&accountcreationtimestamp=xxxxx&subscriptionstarttimestamp=xxxxx

        let altAccountId = this.$store.state.account.account.altAccountId
        let accountCreationTimestamp = this.$store.state.account.account.createdTimestamp || 0

        let link = `https://cam153.typeform.com/to/mYTegoC5#altaccountid=${altAccountId}&accountcreationtimestamp=${accountCreationTimestamp}`

        if(window.open(link, '_blank')) {
          localStorage.setItem('surveyTaken', Date.now().toString())
        }
      },
      async handleAccountPageRedirect() {
        await this.$store.state.accountLoadedPromise
        if(!this.$store.state.inApp &&
          !this.$store.getters.isSubscribed &&
          (!this.$store.state.account.account.emailRecipientFor ||
          this.$store.state.account.account.emailRecipientFor.length === 0) &&
          (!this.$store.state.account.account.emailRecipientInvites ||
            this.$store.state.account.account.emailRecipientInvites.length === 0)
        ) {
          this.goToSubscribePage()
        } else if(
          !this.$store.state.inApp &&
          !this.$store.getters.isSubscribed &&
          (this.$store.state.account.account.emailRecipientFor &&
            this.$store.state.account.account.emailRecipientFor.length > 0) ||
          (this.$store.state.account.account.emailRecipientInvites &&
            this.$store.state.account.account.emailRecipientInvites.length > 0)
        ) {
          this.hideSubscribeContainer = true
        } else if(this.$store.getters.isSubscribed !== false) {
          this.hideSubscribeContainer = true
        }
      },

      /*
        When customers enable e2ee, viriglsecurity will record it, but then sometimes the api call to Truple
        will fail.  This method patches that, by checking everytime the homepage is loaded.  We do the same
        for the account page as well.
       */
      async runE2EEFix() {
        console.log('e2eeFixCheck')
        await this.$store.state.accountLoadedPromise
        console.log('account loaded')
        const accountId = this.$store.state.account.accountId
        const e2eeHelper = E2EEHelperCache.getE2EEHelper(accountId)
        await e2eeHelper.initPromise
        console.log('e2ee loaded')
        if(e2eeHelper.e2eeEnabled) {
          console.log('e2eeEnabled')

          if(!this.$store.getters.isE2EEEnabled) {
            console.log('e2eeFixCheck need to fix')

            try {
              await AccountApi.recordE2EEAction('enabled') //marks the account as e2ee enabled
              console.log('e2eeFixCheck fixed e2ee failed record')
            } catch(e) {
              console.error('e2eeFixCheck failed to fix e2ee failed record')
              console.log(e)
            }
          }
        }
      }
    },
    computed: {
      hasUnknownSites() {
        for(let statsObj of this.deviceEventStats) {
          for(let oObj of statsObj.deviceEventStats) {
            if(oObj.unknownWebsites && oObj.unknownWebsites.length > 0){
              return true
            }
            if(oObj.unknownApps && oObj.unknownApps.length > 0){
              return true
            }
          }
        }
        return false
      },
      showSetupGuideAlert() {
        return this.$store.getters.isSubscribed &&
            this.$store.state.account &&
            this.$store.state.account.account &&
            this.$store.state.account.deviceType === 'OWNER' &&
            !this.$store.state.account.account.setupProgress.minimumSet
      },
      showNoDevices() {
        if(this.$store.state && this.$store.state.devices) {
          let count = this.$store.state.devices.filter(x => { return x.takeSnapshots }).length
          return count === 0
        }
        return !this.$store.state.devices || this.$store.state.devices.length === 0
      }
    },
    mounted() {
      SegmentTracking.page('HomeV2')

      console.log('mounted homev2')
      this.runE2EEFix()
    },
    beforeMount() {
      this.handleAccountPageRedirect()
    },
    beforeDestroy () {
      this.unsubscribeFromStore()
    }
  }
</script>

<style scoped>

</style>
