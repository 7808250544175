import store from '@/store'
//fix for iOS "scroll to top" issue, see https://github.com/vuetifyjs/vuetify/issues/3875
class iOSScrollHack {

  constructor() {
    this.x = null
    this.y = null
  }


  set(val) {
    if(val) {
      this.enable()
    } else {
      this.disable()
    }
  }
  enable() {
    if(store.state.browser.browser.name === 'Safari') {
      this.x = window.scrollX
      this.y = window.scrollY
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    }
  }
  disable() {
    if(store.state.browser.browser.name === 'Safari') {
      document.body.style.overflow = null
      document.body.style.position = null
      if(this.x !== null && this.y !== null) {
        window.scrollTo(this.x, this.y)
        this.x = null
        this.y = null
      }
    }
  }
}

export default (new iOSScrollHack())