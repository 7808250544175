<template>
  <v-dialog v-model="showDialog" max-width="650px" persistent :fullscreen="$vuetify.breakpoint.smAndDown" v-if="this.$store.getters.termsAccepted">
    <v-card>
      <v-card-title class="title">
        <span>Add device request</span><v-spacer /><v-btn icon @click="showDialog = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle class="pt-2">
        A request has been made to add this device to your account. If you are not expecting this, reject the request. Review the information below and make sure you know who's device it is before accepting.
      </v-card-subtitle>
      <v-card-text>
        <v-row wrap dense v-if="this.request">
          <v-col cols="12" class="title">
            <v-divider class="mt-0 mb-2" />
            Request info
          </v-col>
          <v-col cols="12" md="6">
            <span class="font-weight-bold text-body-1">
              Device Name:
            </span>
            <span class="text-body-1">
              {{this.request.deviceName}}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="font-weight-bold text-body-1">
              Device Type:
            </span>
            <span class="text-body-1">
              {{`${this.request.os} (${this.request.deviceMarketName})`}}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="font-weight-bold text-body-1">
              Ip Address:
            </span>
            <span class="text-body-1">
              {{this.request.ipInfo.ipAddress || 'unknown'}}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            <span class="font-weight-bold text-body-1">
              Approx. Location
            </span>
            <span class="text-body-1">
              {{`${this.request.ipInfo.city}, ${this.request.ipInfo.region}`}}
            </span>
          </v-col>
          <v-col cols="12" class="title">
            <v-divider class="mt-2 mb-2" />
            Device Settings
          </v-col>
          <v-col cols="12" md="6">
            <v-switch
                class="pr-0 pr-md-3"
                :disabled="loading"
                prepend-icon="mobile_screen_share"
                color="primary"
                persistent-hint
                v-model="monitoring"
                :label="`Monitoring ${monitoring ? 'Enabled' : 'Disabled'}`"
                :hint="`Enables or disables screenshot monitoring`"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                class="pl-0 pl-md-3"
                :items="request.os === 'android' ? ['OWNER', 'ADMIN', 'USER'] : ['USER']"
                prepend-icon="gavel"
                color="accent" persistent-hint
                v-model="deviceType"
                label="Device Type"
                hint="User devices can only be monitored.  Admin devices can be monitored and view screenshots.  Owner devices can be monitored, view screenshots, change device & account settings."
                :disabled="request.os !== 'android' || loading"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="deviceType !== 'USER'">
            <v-alert type="warning" outlined class="mt-4">
              {{`${deviceType} devices can view screenshots and reports for all devices on the account!`}}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <template v-if="!showBlock">
          <v-btn @click="reject" :disabled="loading">Reject</v-btn>
          <v-btn @click="showBlock = true" :disabled="loading" text x-small color="secondary">Block Ip</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="accept" :loading="loading" :disabled="loading">Accept</v-btn>
          <v-dialog v-model="showAccepted" max-width="400px">
            <v-card>
              <v-card-title>
                Device added
              </v-card-title>
              <v-card-text>
                You've added the device to your account.  Please let the device owner know you've accepted it, and that they should open up their
                {{ appName }} app to start monitoring.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="sendAccepted">Okay</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-else>
          <v-btn @click="showBlock = false">Cancel</v-btn>
          <v-spacer />
          <span class="caption text-center">{{`Are you sure you want to permanently block all requests from ${request.ipInfo.ipAddress}?`}}</span>
          <v-spacer />
          <v-btn @click="block" color="error">Yes</v-btn>
        </template>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreConstants from "@/StoreConstants";
import Utils from "@/Utils";
import AccountApi from "@/aws/account/AccountApi";

export default {
  name: "AddDeviceRequestDialog",
  data() {
     return {
       appName: process.env.VUE_APP_NAME,
       showAccepted: false,
       showBlock: false,
       loading: false,
       showDialog: false,
       request: null,
       monitoring: true,
       deviceType: 'USER',
       deviceTypes: [
           'USER',
           'ADMIN',
           'OWNER'
       ]
     }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async sendAccepted() {
      this.loading = false
      this.showDialog = false
      this.$emit('on-accept')
      await this.checkForNext()
    },
    async initialize() {
      await this.$store.state.accountLoadedPromise

      if(!this.$store.getters.isOwner) {
        this.showDialog = false
        return
      }

      if(!this.$store.state || !this.$store.state.account || !this.$store.state.account.account || !this.$store.state.account.account.addDeviceRequest || this.$store.state.account.account.addDeviceRequest.length === 0) {
        this.showDialog = false
        return
      }

      let requests = this.$store.state.account.account.addDeviceRequest
      this.request = requests[0]
      this.showDialog = true
    },
    async accept() {
      this.loading = true
      try {
        await AccountApi.acceptDeviceRequest({
          identityId: this.request.identityId,
          monitoring: this.monitoring,
          deviceType: this.deviceType
        })
        await Utils.sleep(2000)
        await this.$store.dispatch(StoreConstants.actions.loadDevices)
        this.showAccepted = true
      } catch(e) {
        this.$swal('an error occured: ' + e.toString())
        // eslint-disable-next-line no-console
        console.error(e)
        this.loading = false
        this.showDialog = false
        await this.checkForNext()
      }
    },
    async block() {
      this.loading = true
      try {
        await AccountApi.rejectDeviceRequest({
          identityId: this.request.identityId
        })
        await AccountApi.blockDeviceRequest(
          this.request.ipInfo.ipAddress
        )
      } catch(e) {
        this.$swal('an error occured: ' + e.toString())
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        this.loading = false
        this.showDialog = false
      }
      await this.checkForNext()
    },
    async reject() {
      this.loading = true
      try {
        await AccountApi.rejectDeviceRequest({
          identityId: this.request.identityId
        })
      } catch(e) {
        this.$swal('an error occured: ' + e.toString())
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        this.loading = false
        this.showDialog = false
      }
      await this.checkForNext()
    },
    async checkForNext() {
      await this.$store.dispatch(StoreConstants.actions.loadAccount)
      await Utils.sleep(250)
      await this.initialize()
    }
  }
}
</script>

<style scoped>
</style>
