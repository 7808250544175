import imagesClientFactory from "./apigClient";
import store from "@/store";

class ImagesApi {
  constructor() {
    this.inited = false
    this.client = null
  }

  init() {
    if(!this.inited) {
      this.inited = true
      console.log(process.env.VUE_APP_IMAGES_URL)
      this.client = imagesClientFactory.newClient(store.state.credentials, process.env.VUE_APP_IMAGES_URL)
    }
  }

  async submitAppCorrection(appId) {
    try {
      this.init()

      return await this.client.ratingsPut({appId: appId, deviceUUID: store.state.deviceUUID})
    } catch(e) {
      console.error(e)
    }
  }

  async submitWebsiteCorrection(domain, risk) {
    try {
      this.init()

      return await this.client.ratingsPut({domain: domain, risk: risk, deviceUUID: store.state.deviceUUID})
    } catch(e) {
      console.error(e)
    }
  }
}

export default (new ImagesApi())