import {
  appClosed,
  appOpened,
  phoneOff,
  phoneOn,
  screenOff,
  screenOn,
  screenshotNotTaken,
  screenshotTaken
} from "../events";
import Utils from "../Utils";
import {noRisk} from "../risk";
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import AWSClockSkewHandler from "../aws/AWSClockSkewHandler";
const moment = extendMoment(Moment)

class ScreenTimeCalcs {

  orderScreenTimeEvents(a, b) {
    if(b.timestamp === a.timestamp) {

      if(b.eventType === a.eventType) {
        return 0
      }
      else if(b.eventType === screenOn || b.eventType === phoneOn || b.eventType === appOpened) {
        return -1
      } else if(b.eventType === screenOff || b.eventType === phoneOn || b.eventType === appClosed){
        return 1
      } else {
        return 0
      }
    }

    return b.timestamp - a.timestamp
  }

  calculateScreenStats(events) {
    let screenOnAt = null

    let totalScreenOnOffs = 0
    let totalScreenshotableTime = 0
    let totalNumberOfScreenshots = 0
    let totalTimesScreenshotNotTaken = 0

    for(let c = events.length - 1; c >= 0; c--) {
      let current = events[c]

      if(current.eventType === screenOff) {
        if(screenOnAt !== null) {
          totalScreenshotableTime += current.timestamp - screenOnAt
        }
      } else if(current.eventType === screenOn) {
        totalScreenOnOffs++
        screenOnAt = current.timestamp
      } else if(current.eventType === screenshotTaken) {
        totalNumberOfScreenshots++
      } else if(current.eventType === screenshotNotTaken) {
        totalTimesScreenshotNotTaken++
      } else if(current.eventType === phoneOff) {
        if(screenOnAt !== null) {
          totalScreenshotableTime += current.timestamp - screenOnAt
        }
      } else if(current.eventType === phoneOn) {
        screenOnAt = current.timestamp
      }

      if(c === 0 && screenOnAt !== null &&
        (current.timestamp > AWSClockSkewHandler.getTime() - 3 * 60 * 60 * 1000) //there's been activity within the last 3 hours
      ) { //screen is currently on
        totalScreenshotableTime += AWSClockSkewHandler.getTime() - screenOnAt
      }
    }

    let totalScreenshotableTimeString = Utils.getDurationString(totalScreenshotableTime)
    let averageTimeBetweenScreenshots = totalNumberOfScreenshots > 0 ? Utils.getDurationString(Math.round(totalScreenshotableTime / totalNumberOfScreenshots)) : false

    return {
      totalScreenshotableTime,
      totalNumberOfScreenshots,
      averageTimeBetweenScreenshots,
      totalTimesScreenshotNotTaken,
      totalScreenshotableTimeString,
      totalScreenOnOffs
    }
  }

  calculateAppTimes(events) {

    // let screenOffTime = 0
    // let lastScreenOffAt = null

    let appMap = {}
    for(let c = events.length - 1; c >= 0; c--) {
      let event = events[c]

      // if(event.eventType === 'Screen On') {
      //   if(lastScreenOffAt !== null) {
      //     screenOffTime += event.timestamp - lastScreenOffAt
      //   }
      //   lastScreenOffAt = null;
      // } else if(event.eventType === 'Screen Off') {
      //   lastScreenOffAt = event.timestamp
      // }
      // else
      if(event.eventType === appOpened || event.eventType === appClosed) {

        if(!event.packageName) {
          continue;
        }

        if (!appMap[event.packageName]) {
          appMap[event.packageName] = {
            appName: event.appName,
            packageName: event.packageName,
            totalTime: 0,
            lastOpen: null
          }
        }

        if (event.eventType === appOpened) {
          appMap[event.packageName].lastOpen = event.timestamp
        } else if (event.eventType === appClosed) {
          if(appMap[event.packageName].lastOpen !== null) {
            let totalTimeOpen = event.timestamp - appMap[event.packageName].lastOpen
            appMap[event.packageName].totalTime += totalTimeOpen
            // appMap[event.packageName].totalTime += totalTimeOpen - screenOffTime
            appMap[event.packageName].lastOpen = null;
          }

          // screenOffTime = 0;
          // lastScreenOffAt = null
        }
      }
    }

    return Object.values(appMap).map(x => {
      let mins = x.totalTime / (60 * 1000)
      return [
        x.appName,
        mins.toFixed(2)
      ]
    })
    .filter(x => {
      return x[1] >= 0.25
    })
  }

  calculateScreenUsage(events) {
    let screenOnEvent = null
    let eventPairs = []
    for(let c = events.length - 1; c >= 0; c--) {
      // for(let c = 0; c < events.length; c++) {
      let event = events[c]

      if(event.eventType === screenOn || event.eventType === phoneOn) {
        screenOnEvent = event
      } else if(event.eventType === screenOff || event.eventType === phoneOff) {
        if(screenOnEvent !== null) {
          eventPairs.push([screenOnEvent, event])
          screenOnEvent = null;
        }
      }
    }

    if(screenOnEvent != null) {
      eventPairs.push([screenOnEvent, {
        eventId: 'manually-injected-screen-off',
        eventTitle: screenOff,
        eventType: screenOff,
        timestamp: AWSClockSkewHandler.getTime(),
        riskRating: noRisk
      }])
    }

    let lowestDate = moment(events[events.length - 1].timestamp)
    lowestDate = lowestDate.startOf('hour')

    let highestDate = moment(events[0].timestamp)
    highestDate = highestDate.endOf('hour')

    let currentDate = lowestDate

    let buckets = {

    }

    while(currentDate < highestDate) {
      let currentRange = moment.range(currentDate, moment(currentDate).endOf('hour'))
      let bucketKey = currentDate.format('MMM D LT')

      buckets[bucketKey] = [
        bucketKey,
        0
      ]

      for(let screenEventPairs of eventPairs) {

        let eventStart = moment(screenEventPairs[0].timestamp)
        let eventEnd = moment(screenEventPairs[1].timestamp)
        let eventRange = moment.range(eventStart, eventEnd)

        let intersect = eventRange.intersect(currentRange)
        if(moment.isRange(intersect)) {
          let ms = intersect.diff()
          buckets[bucketKey][1] += ms
        }
      }

      currentDate.add(1, 'hour')
    }

    return Object.values(buckets).map(x => {
      return [
        x[0],
        Math.floor(x[1] / (60 * 1000))
      ]
    })
  }
}

export default (new ScreenTimeCalcs())