var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", fullscreen: "" },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary" } },
            [
              _c("v-toolbar-title", { staticClass: "white--text" }, [
                _vm._v(_vm._s("" + _vm.device.deviceName))
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.onClose } },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mb-4 mt-4" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "centered headline" },
                            [_vm._v("\n              Statistics\n            ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "div",
                                { staticClass: "body-1 ma-2 black--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "Total Screenshots: " +
                                        this.totalNumberOfScreenshots
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "body-1 ma-2 black--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "Screen Time: " +
                                        this.totalScreenshotableTimeString
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "body-1 ma-2 black--text"
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "Actual Screenshot Frequency: " +
                                                    (_vm.averageTimeBetweenScreenshots ===
                                                    false
                                                      ? "...calculating..."
                                                      : _vm.averageTimeBetweenScreenshots)
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      'View "Screenshots NOT Taken" events to understand why the frequency is different than the target average frequency.'
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "body-1 ma-2 black--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "Total Times Screenshot NOT Taken: " +
                                        this.totalTimesScreenshotNotTaken
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "body-1 ma-2 black--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "Screen On / Off: " +
                                        _vm.totalScreenOnOffs +
                                        " times"
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.current > _vm.currentShutoff
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.loading,
                                outlined: "",
                                color: "primary"
                              },
                              on: { click: _vm.loadMore }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.loading ? "" : "load 6 more hours"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("v-spacer")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("screen-time-chart", {
                        staticClass: "mb-3",
                        staticStyle: { height: "100%" },
                        attrs: {
                          "screen-usage": _vm.screenUsage,
                          current: _vm.current
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("app-usage-chart", {
                        staticStyle: { height: "100%" },
                        attrs: {
                          "app-times": _vm.appTimes,
                          current: _vm.current
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }