const phoneOff = 'Phone Turned Off'
const phoneOn = 'Phone Turned On'
const safeModeLaunched = 'Safe Mode Launched'
const safeModeEnded = 'Safe Mode Ended'
const screenshotTaken = 'Screenshot Taken'
const screenshotNotTaken = 'Screenshot NOT Taken'
const screenOn = 'Screen On'
const screenOff = 'Screen Off'
const trupleAppLaunched = 'Truple App Launched'
const trupleAppShutdown = 'Truple App Shutdown'
const appOpened = 'App Opened'
const appClosed = 'App Closed'
const contentChanged = 'Content Changed'
const tamperDetected = 'Tamper Detected'
const appError = 'App Error'
const appPaused = 'App Paused'
const appResumed = 'App Resumed'

const eventTypes = [
  phoneOff,
  phoneOn,
  safeModeLaunched,
  safeModeEnded,
  screenshotTaken,
  screenshotNotTaken,
  screenOn,
  screenOff,
  trupleAppLaunched,
  trupleAppShutdown,
  appOpened,
  appClosed,
  contentChanged,
  tamperDetected,
  appError,
  appPaused,
  appResumed
]

export {
  eventTypes,
  phoneOff,
  phoneOn,
  safeModeLaunched,
  safeModeEnded,
  screenshotTaken,
  screenshotNotTaken,
  screenOn,
  screenOff,
  trupleAppLaunched,
  trupleAppShutdown,
  appOpened,
  appClosed,
  contentChanged,
  tamperDetected,
  appError,
  appPaused,
  appResumed
}