var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", fullscreen: "" },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary" } },
            [
              !_vm.$store.state.isMobile
                ? _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.device.deviceName +
                          " (" +
                          _vm.filteredEvents.length +
                          " events)"
                      )
                    )
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass: "pt-6 mr-1",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "text--white",
                    attrs: {
                      loading: _vm.eventFilterLoading,
                      color: "accent",
                      placeholder: "Search",
                      outlined: "",
                      dense: "",
                      clearable: "",
                      disabled: _vm.loading,
                      "background-color": "white"
                    },
                    on: { change: _vm.startFilterTimeout },
                    model: {
                      value: _vm.tempEventFilter,
                      callback: function($$v) {
                        _vm.tempEventFilter = $$v
                      },
                      expression: "tempEventFilter"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "pt-6",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-select", {
                    staticClass: "text--white",
                    attrs: {
                      items: _vm.riskLabelsByValue,
                      multiple: "",
                      clearable: "",
                      color: "accent",
                      placeholder: "Risk",
                      outlined: "",
                      dense: "",
                      disabled: _vm.loading,
                      "background-color": "white"
                    },
                    model: {
                      value: _vm.selectedRiskFilters,
                      callback: function($$v) {
                        _vm.selectedRiskFilters = $$v
                      },
                      expression: "selectedRiskFilters"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.onClose } },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pl-0 pr-0 pb-0" },
            [
              _vm.filteredEvents.length <= 0 && !_vm.loading
                ? _c("div", { staticClass: "pa-1 mt-5 text-center" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        "No events.  Try loading more, or clearing filters."
                      )
                    ]),
                    _vm.current > _vm.currentShutoff
                      ? _c(
                          "div",
                          { staticClass: "d-flex text-h6" },
                          [
                            _c("v-spacer"),
                            _vm._v("\n          load\n           \n          "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading: _vm.loading6,
                                  disabled: _vm.loading,
                                  outlined: "",
                                  color: "primary"
                                },
                                on: { click: _vm.loadMore }
                              },
                              [_vm._v("\n            6\n          ")]
                            ),
                            _vm._v("\n           \n          "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading: _vm.loading24,
                                  disabled: _vm.loading,
                                  outlined: "",
                                  color: "primary"
                                },
                                on: { click: _vm.load24MoreHours }
                              },
                              [_vm._v("\n            24\n          ")]
                            ),
                            _vm._v(
                              "\n           \n          more hours\n          "
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _c(
                    "v-timeline",
                    {
                      staticClass: "pl-1 pr-1 ml-0 mr-0 pt-1 pb-1",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-virtual-scroll", {
                        attrs: {
                          items: _vm.filteredEvents,
                          "item-height": _vm.eventCardHeight,
                          height: _vm.scrollerHeight,
                          bench: 1
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var index = ref.index
                              var item = ref.item
                              return [
                                !item.loadMore
                                  ? _c(
                                      "v-timeline-item",
                                      {
                                        attrs: {
                                          icon: "public",
                                          color: _vm.getIconColor(item)
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "pt-1 pb-0 text-truncate clickable",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openEventDialogForEvent(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      (item.encrypted &&
                                                        item.decrypted) ||
                                                        !item.encrypted
                                                        ? item.eventBody
                                                          ? item.eventBody
                                                          : " "
                                                        : "Failed to decrypt"
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "pb-1" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.timestampString)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.encrypted
                                                        ? _vm.keyEmoji
                                                        : ""
                                                    )
                                                  )
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.unknown
                                                        ? _vm.unknownEmoji
                                                        : ""
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "d-flex text-h6" },
                                      [
                                        _c("v-spacer"),
                                        _vm._v(
                                          "\n              load\n               \n              "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loading6,
                                              disabled: _vm.loading,
                                              outlined: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.loadMore }
                                          },
                                          [
                                            _vm._v(
                                              "\n                6\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n               \n              "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loading24,
                                              disabled: _vm.loading,
                                              outlined: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.load24MoreHours }
                                          },
                                          [
                                            _vm._v(
                                              "\n                24\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n               \n              more hours\n              "
                                        ),
                                        _c("v-spacer")
                                      ],
                                      1
                                    )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-speed-dial",
            {
              attrs: {
                "open-on-hover": false,
                direction: "top",
                transition: "slide-y-reverse-transition",
                fixed: "",
                bottom: "",
                right: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        { attrs: { color: "accent", fab: "" } },
                        [_c("v-icon", [_vm._v("menu")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { fab: "", color: "primary" },
                  on: { click: _vm.showBreakdown }
                },
                [
                  _c(
                    "v-icon",
                    { staticStyle: { transform: "rotate(90deg)" } },
                    [_vm._v("bar_chart")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: true },
          model: {
            value: _vm.isShowBreakdown,
            callback: function($$v) {
              _vm.isShowBreakdown = $$v
            },
            expression: "isShowBreakdown"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v("Website quantities")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        loading: _vm.loading
                      },
                      on: { click: _vm.loadMore }
                    },
                    [_vm._v("\n          Load 6 more hours\n        ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-12",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.isShowBreakdown = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("bar-chart", {
                            attrs: {
                              data: _vm.breakdownData,
                              ytitle: "Website",
                              xtitle: "Total Times Loaded",
                              suffix: "",
                              height: _vm.breakdownData.length * 15 + 30 + "px",
                              colors: ["#FFC107", "#00796B"]
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("bar-chart", {
                            attrs: {
                              data: _vm.rootDomainBreakdownData,
                              ytitle: "Website",
                              xtitle: "Total Times Loaded",
                              suffix: "",
                              height:
                                _vm.rootDomainBreakdownData.length * 15 +
                                30 +
                                "px",
                              colors: ["#FFC107", "#00796B"]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        outlined: "",
                        color: "primary"
                      },
                      on: { click: _vm.loadMore }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.loading ? "" : "load 6 more hours") +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: true },
          model: {
            value: _vm.showMessage,
            callback: function($$v) {
              _vm.showMessage = $$v
            },
            expression: "showMessage"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.showMessage = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _c("single-event-dialog", {
        attrs: {
          "show-dialog": _vm.showEventDialog,
          item: _vm.eventDialogItem
        },
        on: {
          close: function($event) {
            _vm.showEventDialog = false
          },
          closeall: _vm.cleanupForClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }