<template>
  <v-dialog persistent fullscreen v-model="shown" style="overflow:auto;-webkit-overflow-scrolling:touch">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="!$store.state.isMobile" class="white--text">{{`${device.deviceName}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="pt-6 mr-2" style="display: inline-block">
          <v-switch
            color="white"
            prepend-icon="fiber_new"
            class="toolbar-switch"
            :label="newEmoji"
            :inset="true"
            v-model="newOnlySwitch"
          />
        </div>
        <div class="pt-6 mr-1" style="display: inline-block" v-if="!$store.state.isMobile">
          <v-text-field
            v-model="tempAppFilter"
            :loading="appFilterLoading"
            color="accent"
            placeholder="Search"
            outlined
            dense
            clearable
            :disabled="loading"
            class="text--white"
            @change="startFilterTimeout"
            background-color="white"></v-text-field>
        </div>
        <div class="pt-6" style="display: inline-block">
          <v-select
            v-model="selectedRiskFilters"
            :items="riskLabelsByValue"
            color="accent"
            multiple
            clearable
            placeholder="Risk"
            outlined
            dense
            :disabled="loading"
            @change="forceRender"
            class="text--white"
            background-color="white">

          </v-select>
        </div>
        <v-btn icon dark @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-row v-if="filteredImages.length === 0 && !loading">
          <v-col cols="12" class="pa-1 mt-5 text-center">
            <span class="headline">
              No screenshots. Load more or clear filters.
            </span>
            <div class="d-flex" v-if="startDay <= 14">
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                outlined
                color="primary"
                @click="loadMore(loadMoreTimes)">
                {{loading ? '' : 'load 24 more hours'}}
              </v-btn>
              <v-spacer></v-spacer>
            </div>
          </v-col>
        </v-row>

        <v-virtual-scroll
          :key="scrollKey"
          v-else
          :items="rows"
          :item-height="rowHeight"
          :bench="1"
          :height="scrollerHeight"
        >
          <template v-slot="{ index, item }">
            <div class="img-row" v-if="!item.loadMore">

              <encrypted-thumbnail
                v-for="imageItem in item.items"
                :key="imageItem.fileKey"
                @on-click="viewImage(imageItem.fileKey)"
                :image="imageItem"
                :height="calculatedHeight"
                :width="calculatedWidth"
                :accountId="device.accountId"
                :deviceId="device.deviceId"
                :deviceName="device.deviceName"
                class="thumbnail-img elevation-3 mx-auto d-flex justify-center align-center"
              >
              </encrypted-thumbnail>

<!--              <div-->
<!--                v-for="imageItem in item.items"-->
<!--                :key="imageItem.fileKey"-->
<!--                class="thumbnail-img elevation-3 mx-auto d-flex justify-center align-center"-->
<!--                :class="imageItem.risk"-->
<!--                :style="`height: ${calculatedHeight}px; width: ${calculatedWidth}px; cursor: pointer; background-image: url('${imageItem.thumbnailUrl}'); background-size: cover;`"-->
<!--                @click="viewImage(imageItem.fileKey)"-->
<!--              >-->
<!--&lt;!&ndash;                <img&ndash;&gt;-->
<!--&lt;!&ndash;                  :src="imageItem.thumbnailUrl"&ndash;&gt;-->
<!--&lt;!&ndash;                  :style="`height: ${calculatedHeight - (imageItem.risk === 'high-risk' ? 8 : 0)}px; width: ${calculatedWidth - (imageItem.risk === 'high-risk' ? 8 : 0)}px;`"&ndash;&gt;-->
<!--&lt;!&ndash;                  alt="screenshot accountability image" />&ndash;&gt;-->
<!--              </div>-->
            </div>
            <div v-else class="d-flex justify-center align-center mt-2">
              <v-btn
                :loading="loading"
                outlined
                color="primary"
                @click="loadMore(loadMoreTimes)">
                {{loading ? '' : 'load 24 more hours'}}
              </v-btn>
            </div>


<!--            <v-row no-gutters v-if="!item.loadMore" >-->
<!--              <v-col cols="4" sm="3" md="2" lg="1"-->
<!--                     v-for="(imageItem, imageIndex) in item.items" :key="imageItem.fileKey"-->
<!--              >-->
<!--                <v-card-->
<!--                  :class="imageItem.risk"-->
<!--                  @click="viewImage(index * imagesPerRow + imageIndex)"-->
<!--                  class="ma-1"-->
<!--                >-->
<!--                  <v-img-->
<!--                    :src="imageItem.thumbnailUrl"-->
<!--                    :style="`height: ${calculatedHeight}px;`"-->
<!--                    eager-->
<!--                    alt="screenshot accountability image" />-->
<!--                </v-card>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--            <v-row v-else no-gutters>-->
<!--              <v-col cols="12" class="centered mt-4">-->
<!--                <v-btn-->
<!--                  :loading="loading"-->
<!--                  outlined-->
<!--                  color="primary"-->
<!--                  @click="loadMore">-->
<!--                  {{loading ? '' : 'load 24 more hours'}}-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </template>
        </v-virtual-scroll>
      </v-card-text>

      <photo-gallery
        v-if="showGallery"
        :images="filteredImages"
        :open-index="imageClicked"
        :account-id="device ? device.accountId : null"
        :device-id="device ? device.deviceId : null"
        :visible="showGallery"
        @on-close="closeGallery"
      />

      <v-snackbar
        v-model="showMessage"
        :bottom="true">
        {{ message }}
        <v-btn icon small text color="primary" @click="showMessage = false"><v-icon>close</v-icon></v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="showHighRiskMessage"
        :timeout="-1"
        light

        multi-line
        :top="true">
        <span class="text--theme--error font-weight-bold">High risk</span> screenshots do <b>not</b> guarantee the screenshot contains bad content.
        <span class="text--theme--error font-weight-bold">High risk</span> indicates our algorithms believe the risk to be greater than other screenshots.
        If you'd like to decrease the algorithm's sensitivity, go to the <a @click="goToDeviceSettingsPage">device settings page</a>.
        <br />
        <v-btn class="float-right" icon small text color="primary" @click="neverShowHighRiskMessage"><v-icon>close</v-icon></v-btn>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone'
  import Vue from 'vue'
  import { riskLabelsByValue } from "../../risk"
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";
  import Constants from "../../Constants";
  import EncryptedThumbnail from "../e2ee/EncryptedThumbnail";
  import PhotoGallery from "../images/PhotoGallery";
  import ImageUtils from "../../services/ImageUtils";
  import mobileBackButtonMixin from "../../mixins/mobileBackButtonMixin";
  import NewScreenshotService from "../../services/NewScreenshotService";
  import AndroidInterface from "../../services/AndroidInterface";
  import iOSScrollHack from '../../services/iOSScrollHack'


  const imageLoadIncrement = 0.125

  let appFilterTimeoutHandler = null

  export default {
    name: "ScreenshotDialog",
    mixins: [
      mobileBackButtonMixin('onClose')
    ],
    components: {PhotoGallery, EncryptedThumbnail },
    props: {
      daysToLoad: {
        type: Number
      },
      device: {
        type: Object,
        required: true
      },
      risk: {
        type: String,
        required: false
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        loadMoreTimes: 1 / imageLoadIncrement,
        showHighRiskMessage: false,
        newEmoji: Constants.newEmoji,
        newOnlySwitch: false,
        scrollKey: 0,
        showMessage: false,
        message: null,
        loading: false,
        imageClicked: null,
        selectedRiskFilters: [],
        tempAppFilter: '',
        appFilter: '',
        appFilterLoading: false,
        riskLabelsByValue: riskLabelsByValue,
        startDay: 0,
        images: [],
        shown: false,
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
        showGallery: false
      }
    },
    beforeMount() {

      console.log('screenshot dialog mounted')

      this.startDay = 0
      if(this.device && this.device.deviceId) {
        this.loadImages()
      }

      window.addEventListener('resize', this.onResize)

      this.setShown(this.$props.showDialog)
      if(this.$props.risk && this.selectedRiskFilters && this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
        this.selectedRiskFilters.push(this.$props.risk)
      }
    },
    beforeDestroy() {
      this.markImagesAsSeen()

      console.log('beforeDestroy')
      this.cleanupForClose()

      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize)
      }
    },
    methods: {
      setShown(val) {
        //fix for iOS "scroll to top" issue, see https://github.com/vuetifyjs/vuetify/issues/3875
        this.shown = val
        iOSScrollHack.set(val)
      },
      goToDeviceSettingsPage() {
        //for some reason router-link to="" doesn't work in snackbar
        this.cleanupForClose()
        setTimeout(() => {
          if(this.$store.state.inApp) {
            AndroidInterface.openAppPageInBrowser('devices')
          } else {
            this.$router.push('devices')
          }
        }, 200)
      },
      neverShowHighRiskMessage() {
        this.showHighRiskMessage = false
        //hide for 3 months
        localStorage.setItem('last-showed-high-risk-message', (Date.now() + 24 * 60 * 60 * 1000) + "")
      },
      markImagesAsSeen() {
        NewScreenshotService.markImagesAsSeen(this.images)
      },
      forceRender() {
        this.scrollKey++
        console.log('force render')
        //sometimes the vue-virtual-list doesn't render after adjusting filters it seems
      },
      onResize() {
        this.windowInnerWidth = window.innerWidth
        this.windowInnerHeight = window.innerHeight
        this.$nextTick(() => {
          this.forceRender()
        })
      },
      startFilterTimeout() {
        clearTimeout(appFilterTimeoutHandler)
        this.appFilterLoading = true
        appFilterTimeoutHandler = setTimeout(() => {
          this.appFilter = this.tempAppFilter
          console.log('filter set')
          this.appFilterLoading = false
          this.forceRender()
        }, 1500)
      },
      viewImage(fileKey) {
        console.log(`viewImage ${fileKey}`)
        let index = -1;
        for(let c = 0; c < this.filteredImages.length; c++) {
          if(this.filteredImages[c].fileKey === fileKey) {
            console.log('index = ' + c)
            index = c;
            break;
          }
        }
        if(index !== -1) {
          this.showGallery = false
          this.$nextTick(() => {
            this.showGallery = true
          })
          console.log('imageClicked = ' + index)
          this.imageClicked = index
        }
      },
      closeGallery() {
        this.showGallery = false
        this.imageClicked = -1
      },
      cleanupForClose() {
        // this.images = []
        // this.startDay = 0
        // this.imageClicked = null
        // this.shown = false
        // this.appFilter = null
        // this.tempAppFilter = null
        // this.selectedRiskFilters = []
        this.setShown(false)
        this.$emit('on-close')
      },
      onClose() {
        this.cleanupForClose()
      },
      async loadMore(times) {
        times = times || 1
        for(let c = 0; c < times; c++) {
          this.startDay += imageLoadIncrement
          await this.loadImages()
        }
      },
      async loadImages() {
        console.log('loadImages')

        if(!this.device || !this.device.deviceId) {
          return
        }

        this.loading = true
        try {

          // console.log(`asking for ${this.device.accountId} and ${this.device.deviceId}`)

          let results = await this.api.getImageRecordsAwait(this.startDay, this.startDay + imageLoadIncrement, this.device.deviceId, this.device.accountId)

          let tempImages = this.images.concat(results.data)

          let filteredImages = []
          let fileKeySet = new Set()
          for(let i of tempImages) {
            if(fileKeySet.has(i.fileKey)) {
              // eslint-disable-next-line no-console
              console.log(`image ${i.fileKey} duplicate (${new Date(i.takenAt).toLocaleString()})`)
              continue
            }

            if(i.downloadUrl === Constants.insufficientSubscriptionUrl) {
              i.imageRatio = Constants.insufficientSubscriptionImageRatio
              i.encrypted = false
            }

            if(i.risk === Constants.highRisk && !this.showHighRiskMessage && i.riskReason && i.riskReason.indexOf('image content') !== -1) {
              let lastShowed = localStorage.getItem('last-showed-high-risk-message')
              if(lastShowed) {
                try {
                  lastShowed = parseInt(lastShowed)
                  if(Date.now() - lastShowed > 15 * 60 * 60 * 1000) {
                    this.showHighRiskMessage = true
                    localStorage.setItem('last-showed-high-risk-message', Date.now() + "")
                  }
                } catch(e) {
                  this.showHighRiskMessage = true
                  localStorage.setItem('last-showed-high-risk-message', Date.now() + "")
                }
              } else {
                this.showHighRiskMessage = true
                localStorage.setItem('last-showed-high-risk-message', Date.now() + "")
              }
            }

            fileKeySet.add(i.fileKey)
            filteredImages.push(i)
          }

          filteredImages.sort((a, b) => {
            if (a.takenAt < b.takenAt) {
              return 1
            } else if (a.takenAt > b.takenAt) {
              return -1
            } else {
              return 0
            }
          })

          //we can remove this once the app update that fixed rotated image ratios is common enough.  Launched july 2020
          // eslint-disable-next-line no-unused-vars
          let { mostCommonImageRatio, largestRatio } = ImageUtils.getImageRatioInfo(filteredImages)

          let imageRatioOverride = largestRatio

          let frozenImages = []

          let alreadyViewedScreenshots = NewScreenshotService.getSeenImages()

          for(let i of filteredImages) {
            if(i.imageRatio !== Constants.insufficientSubscriptionImageRatio && parseFloat(i.imageRatio).toFixed(2) !== parseFloat(imageRatioOverride).toFixed(2)) {
              i.imageRatio = imageRatioOverride
            }

            if(!alreadyViewedScreenshots.has(i.takenAt)) {
              i.neverBeforeSeen = true
            } else {
              i.neverBeforeSeen = false
            }

            // frozenImages.push(Object.freeze(i))
            frozenImages.push(i) //freezing was causing issues
          }

          this.images = frozenImages

        } catch (error) {
          console.log(error)
          this.$swal('error loading images... please try again.  If this continues, contact support@truple.io')
        } finally {
          if((this.filteredImages.length < 36 && this.startDay + imageLoadIncrement < 1) || (this.daysToLoad && this.startDay + imageLoadIncrement < this.daysToLoad)) {
            this.loadMore(1)
          } else {
            //Vue.nextTick helps load the images better within vue-virtual-list for some reason
            Vue.nextTick(() => {
              //shouldn't be necessary, but sometimes when no events are there it fails to show the load more button
              if(this.images && this.images.length === 0) {
                this.forceRender()
              }

              this.showMessage = true
              this.message = `Loaded to ${this.getSinceString}`
              this.loading = false
            })
          }
        }
      }
    },
    watch: {
      device() {
        console.log('device changed')
        if(this.$props.device && this.$props.device.deviceId) {
          this.startDay = 0
          this.loadImages()
        }
      },
      risk() {
        if(this.$props.risk && this.selectedRiskFilters && this.selectedRiskFilters.indexOf(this.$props.risk) === -1) {
          this.selectedRiskFilters.push(this.$props.risk)
        } else {
          this.selectedRiskFilters = []
        }
      },
      showDialog() {
        this.setShown(this.$props.showDialog)
      }
    },
    computed: {
      scrollerHeight() {
        return (this.windowInnerHeight - Constants.titleBarHeight - 16) + 'px'
      },
      getSinceString() {
        return moment.utc(AWSClockSkewHandler.getTime())
            .add((this.startDay + imageLoadIncrement) * -1, 'days')
            .tz(moment.tz.guess()).format('LLLL')
      },
      imagesPerRow() {

        //no longer tied to a grid, so we can return however many we want.

        if(this.windowInnerWidth <= 300) {
          return 2
        } else if(this.windowInnerWidth < 600) {
          return 3
        } else if(this.windowInnerWidth < 800) {
          return 4
        } else if(this.windowInnerWidth < 1000) {
          return 5
        } else if(this.windowInnerWidth < 1250) {
          return 6
        } else if(this.windowInnerWidth < 1500) {
          return 8
        } else if(this.windowInnerWidth < 1800) {
          return 10
        } else if(this.windowInnerWidth < 2000) {
          return 10
        } else {
          return 12
        }

        // if(this.windowInnerWidth < 400) {
        //   return 2
        // }
        // else if(this.windowInnerWidth < 600) {
        //   //xs
        //   return 3
        // } else if(this.windowInnerWidth < 960) {
        //   //sm
        //   return 6
        // } else if(this.windowInnerWidth < 1264) {
        //   //md
        //   return 6
        // } else if(this.windowInnerWidth < 1904) {
        //   //lg
        //   return 12
        // } else {
        //   //xl
        //   return 12
        // }
      },
      calculatedWidth() {

        let padding = 4
        let horiztonalPaddingPerImage = padding * 2

        let ipr = this.imagesPerRow

        let totalHorizontalPaddingPerRow = ipr * horiztonalPaddingPerImage

        let width = ((this.windowInnerWidth - totalHorizontalPaddingPerRow) / ipr)

        return width
      },
      rowHeight() {
        return this.calculatedHeight + 8;
      },
      calculatedHeight() {
        if(!this.images || this.images.length === 0) {
          return 52
        }

        //calculate width based off innerWidth & number of items
        let height = Math.ceil(this.images[0].imageRatio * this.calculatedWidth)

        return height
      },
      rows() {
        let ipr = this.imagesPerRow

        let ret = []
        let row = {
          id: '',
          items: []
        }
        let imagesPerRowCounter = 0
        for(let i of this.filteredImages) {
          row.items.push(i)
          row.id += i.fileKey
          imagesPerRowCounter++
          if(imagesPerRowCounter >= ipr) {
            imagesPerRowCounter = 0
            ret.push(Object.freeze(row))
            row = {
              id: '',
              items: []
            }
          }
        }

        if(row.items.length > 0) {
          ret.push(Object.freeze(row))
        }

        if(this.startDay <= 14) {
          ret.push({
            loadMore: true
          })
        }

        return ret
      },

      filteredImages() {
        let ret = []
        let riskSet = new Set(this.selectedRiskFilters)

        let lowerCaseAppFilter = this.appFilter ? this.appFilter.toLowerCase() : ''

        for(let i of this.images) {

          if(this.newOnlySwitch) {
            if(!i.neverBeforeSeen) {
              continue;
            }
          }

          if(riskSet.size > 0) {
            if(!riskSet.has(i.risk)) {
              continue
            }
          }
          if(lowerCaseAppFilter.length > 0) {

            let appName = i.appName ? i.appName.toLowerCase() : ''
            let appDetails = i.appDetails ? i.appDetails.toLowerCase() : ''
            let packageName = i.packageName ? i.packageName.toLowerCase() : ''

            if(!appName.includes(lowerCaseAppFilter) &&
              !appDetails.includes(lowerCaseAppFilter) &&
              !packageName.includes(lowerCaseAppFilter)) {
              continue
            }
          }
          ret.push(i)
        }

        return ret
      }
    }
  }
</script>

<style scoped lang="less">
  .img-row {
    display: flex;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .toolbar-switch {
    > v-input__append-outer {
      margin-left: 0 !important;
    }
  }
</style>
