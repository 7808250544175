import { render, staticRenderFns } from "./DeviceCard.vue?vue&type=template&id=08923e14&scoped=true&"
import script from "./DeviceCard.vue?vue&type=script&lang=js&"
export * from "./DeviceCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08923e14",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Cam\\projects\\netcountable-app\\truple\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08923e14')) {
      api.createRecord('08923e14', component.options)
    } else {
      api.reload('08923e14', component.options)
    }
    module.hot.accept("./DeviceCard.vue?vue&type=template&id=08923e14&scoped=true&", function () {
      api.rerender('08923e14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/home/DeviceCard.vue"
export default component.exports