var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticStyle: { overflow: "auto", "-webkit-overflow-scrolling": "touch" },
      attrs: { persistent: "", fullscreen: "" },
      model: {
        value: _vm.shown,
        callback: function($$v) {
          _vm.shown = $$v
        },
        expression: "shown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary" } },
            [
              !_vm.$store.state.isMobile
                ? _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v(_vm._s("" + _vm.device.deviceName))
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass: "pt-6 mr-2",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-switch", {
                    staticClass: "toolbar-switch",
                    attrs: {
                      color: "white",
                      "prepend-icon": "fiber_new",
                      label: _vm.newEmoji,
                      inset: true
                    },
                    model: {
                      value: _vm.newOnlySwitch,
                      callback: function($$v) {
                        _vm.newOnlySwitch = $$v
                      },
                      expression: "newOnlySwitch"
                    }
                  })
                ],
                1
              ),
              !_vm.$store.state.isMobile
                ? _c(
                    "div",
                    {
                      staticClass: "pt-6 mr-1",
                      staticStyle: { display: "inline-block" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "text--white",
                        attrs: {
                          loading: _vm.appFilterLoading,
                          color: "accent",
                          placeholder: "Search",
                          outlined: "",
                          dense: "",
                          clearable: "",
                          disabled: _vm.loading,
                          "background-color": "white"
                        },
                        on: { change: _vm.startFilterTimeout },
                        model: {
                          value: _vm.tempAppFilter,
                          callback: function($$v) {
                            _vm.tempAppFilter = $$v
                          },
                          expression: "tempAppFilter"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "pt-6",
                  staticStyle: { display: "inline-block" }
                },
                [
                  _c("v-select", {
                    staticClass: "text--white",
                    attrs: {
                      items: _vm.riskLabelsByValue,
                      color: "accent",
                      multiple: "",
                      clearable: "",
                      placeholder: "Risk",
                      outlined: "",
                      dense: "",
                      disabled: _vm.loading,
                      "background-color": "white"
                    },
                    on: { change: _vm.forceRender },
                    model: {
                      value: _vm.selectedRiskFilters,
                      callback: function($$v) {
                        _vm.selectedRiskFilters = $$v
                      },
                      expression: "selectedRiskFilters"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.onClose } },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _vm.filteredImages.length === 0 && !_vm.loading
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-1 mt-5 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("span", { staticClass: "headline" }, [
                            _vm._v(
                              "\n              No screenshots. Load more or clear filters.\n            "
                            )
                          ]),
                          _vm.startDay <= 14
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        outlined: "",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loadMore(_vm.loadMoreTimes)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.loading
                                              ? ""
                                              : "load 24 more hours"
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _c("v-virtual-scroll", {
                    key: _vm.scrollKey,
                    attrs: {
                      items: _vm.rows,
                      "item-height": _vm.rowHeight,
                      bench: 1,
                      height: _vm.scrollerHeight
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var index = ref.index
                          var item = ref.item
                          return [
                            !item.loadMore
                              ? _c(
                                  "div",
                                  { staticClass: "img-row" },
                                  _vm._l(item.items, function(imageItem) {
                                    return _c("encrypted-thumbnail", {
                                      key: imageItem.fileKey,
                                      staticClass:
                                        "thumbnail-img elevation-3 mx-auto d-flex justify-center align-center",
                                      attrs: {
                                        image: imageItem,
                                        height: _vm.calculatedHeight,
                                        width: _vm.calculatedWidth,
                                        accountId: _vm.device.accountId,
                                        deviceId: _vm.device.deviceId,
                                        deviceName: _vm.device.deviceName
                                      },
                                      on: {
                                        "on-click": function($event) {
                                          return _vm.viewImage(
                                            imageItem.fileKey
                                          )
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center mt-2"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.loading,
                                          outlined: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.loadMore(
                                              _vm.loadMoreTimes
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.loading
                                                ? ""
                                                : "load 24 more hours"
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  })
            ],
            1
          ),
          _vm.showGallery
            ? _c("photo-gallery", {
                attrs: {
                  images: _vm.filteredImages,
                  "open-index": _vm.imageClicked,
                  "account-id": _vm.device ? _vm.device.accountId : null,
                  "device-id": _vm.device ? _vm.device.deviceId : null,
                  visible: _vm.showGallery
                },
                on: { "on-close": _vm.closeGallery }
              })
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { bottom: true },
              model: {
                value: _vm.showMessage,
                callback: function($$v) {
                  _vm.showMessage = $$v
                },
                expression: "showMessage"
              }
            },
            [
              _vm._v("\n        " + _vm._s(_vm.message) + "\n        "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "", text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showMessage = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: -1, light: "", "multi-line": "", top: true },
              model: {
                value: _vm.showHighRiskMessage,
                callback: function($$v) {
                  _vm.showHighRiskMessage = $$v
                },
                expression: "showHighRiskMessage"
              }
            },
            [
              _c(
                "span",
                { staticClass: "text--theme--error font-weight-bold" },
                [_vm._v("High risk")]
              ),
              _vm._v(" screenshots do "),
              _c("b", [_vm._v("not")]),
              _vm._v(
                " guarantee the screenshot contains bad content.\n        "
              ),
              _c(
                "span",
                { staticClass: "text--theme--error font-weight-bold" },
                [_vm._v("High risk")]
              ),
              _vm._v(
                " indicates our algorithms believe the risk to be greater than other screenshots.\n        If you'd like to decrease the algorithm's sensitivity, go to the "
              ),
              _c("a", { on: { click: _vm.goToDeviceSettingsPage } }, [
                _vm._v("device settings page")
              ]),
              _vm._v(".\n        "),
              _c("br"),
              _c(
                "v-btn",
                {
                  staticClass: "float-right",
                  attrs: { icon: "", small: "", text: "", color: "primary" },
                  on: { click: _vm.neverShowHighRiskMessage }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }