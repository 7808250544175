function getFromLocalStorage() {
  let raw = localStorage.getItem('seen-images')
  if(raw) {
    try {
      let objs = JSON.parse(raw)

      objs.filter(x => {
        return x >= Date.now() - 8 * 24 * 60 * 60 * 1000
      })

      return objs
    } catch(e) {
      console.log(e)
      return []
    }
  } else {
    return []
  }
}

class NewScreenshotService {
  markImagesAsSeen(listOfScreenshots) {
    let set = new Set(getFromLocalStorage())
    for(let i of listOfScreenshots) {
      if(!set.has(i.takenAt)) {
        set.add(i.takenAt)
      }
    }
    localStorage.setItem('seen-images', JSON.stringify(Array.from(set)))
  }

  getSeenImages() {
    return new Set(getFromLocalStorage())
  }
}

export default (new NewScreenshotService())