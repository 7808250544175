<template>
  <v-dialog v-model="getNextE2EEHelperWithoutPrivateCard" v-if="getNextE2EEHelperWithoutPrivateCard" max-width="700" :fullscreen="$store.state.isMobile">
    <v-card>
      <v-card-title>End-to-End Encryption Passcode</v-card-title>
      <v-card-subtitle>Passcode required for:</v-card-subtitle>
      <v-card-text>
        <v-card class="title mb-5 pa-3 text-center text--white" color="accent" shaped>
          {{getNextE2EEHelperWithoutPrivateCard.accountId}}
        </v-card>

        <input type="text" name="usr" :value="decryptionPasscodeUsername" style="display: none;" :readonly="true" autocomplete="username" />

        <e2-e-e-passcode-input
            outlined
            type="password"
            autocomplete="current-password"
            class="mb-2"
            :disabled="loading"
            color="primary"
            v-model="passcode"
            label="E2EE Key Recovery Passcode"
            placeholder="E2EE Passcode"
            :hint="`Please provide the end-to-end encryption recovery passcode for ${getNextE2EEHelperWithoutPrivateCard.accountId}.`"
            :persistent-hint="true"
        />

        <v-alert v-if="errorMessage" type="error">
          {{errorMessage}}
        </v-alert>

        <div>
          <a :href="`https://${supportDomain}/articles/misc/why-asked-for-end-to-end-encryption-passcode`" target="_blank">Why are you being asked to enter this?</a>
        </div>

        <div class="font-italic"><span>{{`If you do not know the passcode, please ask ${getNextE2EEHelperWithoutPrivateCard.accountId} for it.`}}</span><br /><span><span>{{`If needed ${getNextE2EEHelperWithoutPrivateCard.accountId} can `}}</span> <a :href="`https://${supportDomain}/articles/account/reset-end-to-end-encryption-passcode`" target="_blank">reset the passcode</a>.</span></div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" :disabled="loading">Close</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="submit" :disabled="loading" :loading="loading">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import E2EEHelperCache from "../../services/e2ee/E2EEHelperCache";
  import E2EEPasscodeInput from "@/components/e2ee/E2EEPasscodeInput.vue";

  export default {
    name: "E2EERecoveryPasswordRequest",
    components: {E2EEPasscodeInput},
    data() {
      return {
        supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
        loading: false,
        e2eeHelpers: [],
        passcode: '',
        errorMessage: null
      }
    },
    methods: {
      async close() {
        console.log('close it!')

        let e2eeHelper = this.getNextE2EEHelperWithoutPrivateCard
        e2eeHelper.bypassPrivateKeyMissing = true

        await this.$swal({
          icon: 'warning',
          title: 'E2EE passcode required',
          text: `You will not be able to view data from ${e2eeHelper.accountId}'s account that's been end-to-end encrypted.  Please ${this.$store.state.inApp ? 'close and reopen the app' : 'refresh the page'} to enter the e2ee passcode.`
        })
      },
      async submit() {
        this.loading = true
        let e2eeHelper = this.getNextE2EEHelperWithoutPrivateCard
        try {
          console.log('downloading private key')
          await e2eeHelper.downloadPrivateKey(this.passcode)
          this.loadE2EE()
          this.passcode = ''
          this.errorMessage = null
        } catch(e) {
          console.log(e)
          this.errorMessage = e
          this.loading = false
        }
      },
      async loadE2EE() {
        this.loading = true
        this.e2eeHelpers = []
        await this.$store.state.accountLoadedPromise
        let e2eeHelper = E2EEHelperCache.getE2EEHelper(this.$store.state.account.account.accountId)
        await e2eeHelper.initPromise
        this.e2eeHelpers.push(e2eeHelper)

        if(this.$store.state.account.deviceType === 'OWNER' && this.$store.state.account.account.emailRecipientFor) {
          for(let emailRecipientObj of this.$store.state.account.account.emailRecipientFor) {
            let e2eeHelper = E2EEHelperCache.getE2EEHelper(emailRecipientObj.accountId)
            await e2eeHelper.initPromise
            this.e2eeHelpers.push(e2eeHelper)
          }
        }
        this.loading = false
      }
    },
    computed: {
      decryptionPasscodeUsername() {
        if(!this.getNextE2EEHelperWithoutPrivateCard) {
          return 'unknown'
        } else {
          return `${this.getNextE2EEHelperWithoutPrivateCard.accountId}.decryption.passcode`
        }
      },
      getNextE2EEHelperWithoutPrivateCard: {
        get() {
          for(let e2EEHelper of this.e2eeHelpers) {
            //dont load the e2ee code when in the app for report recipient accounts
            // technically we may want to update this at some point so account owners can view accountability
            // data for people they act as report recipients for, but so far we haven't done that.
            if((!this.$store.state.inApp || this.$store.getters.isOwner) || (this.$store.state.inApp && e2EEHelper.accountId === this.$store.state.account.accountId)) {
              if(e2EEHelper.e2eeEnabled && !e2EEHelper.bypassPrivateKeyMissing) {
                if(!e2EEHelper.hasLocalKey) {
                  return e2EEHelper
                }
              }
            }
          }
          return null
        },
        set() {
          console.log('we don\'t do set')
        }
      }
    },
    beforeMount() {
      this.loadE2EE()
    },
  }
</script>

<style scoped>

</style>
