var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getNextE2EEHelperWithoutPrivateCard
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "700", fullscreen: _vm.$store.state.isMobile },
          model: {
            value: _vm.getNextE2EEHelperWithoutPrivateCard,
            callback: function($$v) {
              _vm.getNextE2EEHelperWithoutPrivateCard = $$v
            },
            expression: "getNextE2EEHelperWithoutPrivateCard"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("End-to-End Encryption Passcode")]),
              _c("v-card-subtitle", [_vm._v("Passcode required for:")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "title mb-5 pa-3 text-center text--white",
                      attrs: { color: "accent", shaped: "" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getNextE2EEHelperWithoutPrivateCard.accountId
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _c("input", {
                    staticStyle: { display: "none" },
                    attrs: {
                      type: "text",
                      name: "usr",
                      readonly: true,
                      autocomplete: "username"
                    },
                    domProps: { value: _vm.decryptionPasscodeUsername }
                  }),
                  _c("e2-e-e-passcode-input", {
                    staticClass: "mb-2",
                    attrs: {
                      outlined: "",
                      type: "password",
                      autocomplete: "current-password",
                      disabled: _vm.loading,
                      color: "primary",
                      label: "E2EE Key Recovery Passcode",
                      placeholder: "E2EE Passcode",
                      hint:
                        "Please provide the end-to-end encryption recovery passcode for " +
                        _vm.getNextE2EEHelperWithoutPrivateCard.accountId +
                        ".",
                      "persistent-hint": true
                    },
                    model: {
                      value: _vm.passcode,
                      callback: function($$v) {
                        _vm.passcode = $$v
                      },
                      expression: "passcode"
                    }
                  }),
                  _vm.errorMessage
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                        )
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://" +
                            _vm.supportDomain +
                            "/articles/misc/why-asked-for-end-to-end-encryption-passcode",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Why are you being asked to enter this?")]
                    )
                  ]),
                  _c("div", { staticClass: "font-italic" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          "If you do not know the passcode, please ask " +
                            _vm.getNextE2EEHelperWithoutPrivateCard.accountId +
                            " for it."
                        )
                      )
                    ]),
                    _c("br"),
                    _c("span", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            "If needed " +
                              _vm.getNextE2EEHelperWithoutPrivateCard
                                .accountId +
                              " can "
                          )
                        )
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://" +
                              _vm.supportDomain +
                              "/articles/account/reset-end-to-end-encryption-passcode",
                            target: "_blank"
                          }
                        },
                        [_vm._v("reset the passcode")]
                      ),
                      _vm._v(".")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.close }
                    },
                    [_vm._v("Close")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.loading,
                        loading: _vm.loading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Submit")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }