var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.getters.termsAccepted
    ? _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650px",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "title" },
                [
                  _c("span", [_vm._v("Add device request")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card-subtitle", { staticClass: "pt-2" }, [
                _vm._v(
                  "\n      A request has been made to add this device to your account. If you are not expecting this, reject the request. Review the information below and make sure you know who's device it is before accepting.\n    "
                )
              ]),
              _c(
                "v-card-text",
                [
                  this.request
                    ? _c(
                        "v-row",
                        { attrs: { wrap: "", dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "title", attrs: { cols: "12" } },
                            [
                              _c("v-divider", { staticClass: "mt-0 mb-2" }),
                              _vm._v("\n          Request info\n        ")
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "span",
                              { staticClass: "font-weight-bold text-body-1" },
                              [_vm._v("\n            Device Name:\n          ")]
                            ),
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(this.request.deviceName) +
                                  "\n          "
                              )
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "span",
                              { staticClass: "font-weight-bold text-body-1" },
                              [_vm._v("\n            Device Type:\n          ")]
                            ),
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    this.request.os +
                                      " (" +
                                      this.request.deviceMarketName +
                                      ")"
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "span",
                              { staticClass: "font-weight-bold text-body-1" },
                              [_vm._v("\n            Ip Address:\n          ")]
                            ),
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    this.request.ipInfo.ipAddress || "unknown"
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "span",
                              { staticClass: "font-weight-bold text-body-1" },
                              [
                                _vm._v(
                                  "\n            Approx. Location\n          "
                                )
                              ]
                            ),
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    this.request.ipInfo.city +
                                      ", " +
                                      this.request.ipInfo.region
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "title", attrs: { cols: "12" } },
                            [
                              _c("v-divider", { staticClass: "mt-2 mb-2" }),
                              _vm._v("\n          Device Settings\n        ")
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-switch", {
                                staticClass: "pr-0 pr-md-3",
                                attrs: {
                                  disabled: _vm.loading,
                                  "prepend-icon": "mobile_screen_share",
                                  color: "primary",
                                  "persistent-hint": "",
                                  label:
                                    "Monitoring " +
                                    (_vm.monitoring ? "Enabled" : "Disabled"),
                                  hint:
                                    "Enables or disables screenshot monitoring"
                                },
                                model: {
                                  value: _vm.monitoring,
                                  callback: function($$v) {
                                    _vm.monitoring = $$v
                                  },
                                  expression: "monitoring"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                staticClass: "pl-0 pl-md-3",
                                attrs: {
                                  items:
                                    _vm.request.os === "android"
                                      ? ["OWNER", "ADMIN", "USER"]
                                      : ["USER"],
                                  "prepend-icon": "gavel",
                                  color: "accent",
                                  "persistent-hint": "",
                                  label: "Device Type",
                                  hint:
                                    "User devices can only be monitored.  Admin devices can be monitored and view screenshots.  Owner devices can be monitored, view screenshots, change device & account settings.",
                                  disabled:
                                    _vm.request.os !== "android" || _vm.loading
                                },
                                model: {
                                  value: _vm.deviceType,
                                  callback: function($$v) {
                                    _vm.deviceType = $$v
                                  },
                                  expression: "deviceType"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.deviceType !== "USER"
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { type: "warning", outlined: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.deviceType +
                                              " devices can view screenshots and reports for all devices on the account!"
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  !_vm.showBlock
                    ? [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: _vm.loading },
                            on: { click: _vm.reject }
                          },
                          [_vm._v("Reject")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: _vm.loading,
                              text: "",
                              "x-small": "",
                              color: "secondary"
                            },
                            on: {
                              click: function($event) {
                                _vm.showBlock = true
                              }
                            }
                          },
                          [_vm._v("Block Ip")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              loading: _vm.loading,
                              disabled: _vm.loading
                            },
                            on: { click: _vm.accept }
                          },
                          [_vm._v("Accept")]
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "400px" },
                            model: {
                              value: _vm.showAccepted,
                              callback: function($$v) {
                                _vm.showAccepted = $$v
                              },
                              expression: "showAccepted"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _vm._v(
                                    "\n              Device added\n            "
                                  )
                                ]),
                                _c("v-card-text", [
                                  _vm._v(
                                    "\n              You've added the device to your account.  Please let the device owner know you've accepted it, and that they should open up their\n              " +
                                      _vm._s(_vm.appName) +
                                      " app to start monitoring.\n            "
                                  )
                                ]),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.sendAccepted }
                                      },
                                      [_vm._v("Okay")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function($event) {
                                _vm.showBlock = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c("v-spacer"),
                        _c("span", { staticClass: "caption text-center" }, [
                          _vm._v(
                            _vm._s(
                              "Are you sure you want to permanently block all requests from " +
                                _vm.request.ipInfo.ipAddress +
                                "?"
                            )
                          )
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error" },
                            on: { click: _vm.block }
                          },
                          [_vm._v("Yes")]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }