<template>
  <v-card class="pa-2">
    <div style="text-align: center;" class="mb-1 headline">{{`Screen Time from previous ${this.hoursLoaded} hours`}}</div>
    <v-divider></v-divider>
    <bar-chart :data="screenUsage"
               ytitle="Hour of day"
               xtitle="Screen time"
               suffix=" mins"
               :height="`${screenUsage.length * 20 + 100}px`"
               :colors="['#FFC107', '#00796B']"
    ></bar-chart>
  </v-card>
</template>

<script>
  import AWSClockSkewHandler from "../../aws/AWSClockSkewHandler";

  export default {
    name: "ScreenTimeChart",
    props: {
      screenUsage: {
        required: true
      },
      current: {
        required: true
      }
    },
    computed: {
      hoursLoaded() {
        return Math.round((AWSClockSkewHandler.getTime() - this.current) / (60 * 60 * 1000))
      }
    }
  }
</script>

<style scoped>

</style>